<template>
  <div class="loadingBox" v-if="loading">
    <div class="loader">鲁班工程职业学院...</div>
  </div>
  <div
  class="appbox"
  ref="appbox"
  >
    <!-- 轮播图 -->
    <div class="swiper showdiv" :style="{'height':swiperHeight2}">
      <el-carousel height="auto" autoplay arrow="never" ref="carousel" :interval="intervalTime" trigger="click" :pause-on-hover='true' @change="carouselChange">
        <el-carousel-item
          v-for="(item, index) in store?.state?.nav?.homeSwiper"
          :key="index"
          :style="{'height': swiperHeight2}"
        >
          <template #default="scope">
              <img ref="bannerIMG" :src="item.picturePath" alt="" style="width:120rem;cursor: pointer;"
              :style="{'height':swiperHeight2}"
              @click.stop="handleClickJumpArticle(item)"
              v-if="item.functionType==3"
              />
              <!-- <video v-if="item.functionType==64" id="video2" preload="auto"  playsinline webkit-playsinline x5-playsinline style="width:120rem;cursor: pointer;"
              :style="{'height':swiperHeight2}"></video> -->
              <video 
              v-if="item.functionType==64"  
              :id="item.name" 
              :src="item.picturePath" 
              style="width:120rem;cursor: pointer;object-fit: fill;" 
              :style="{'height':swiperHeight2}" 
              muted="muted"
              ></video>

              <div class="itemBox">
                <div :class="{item,active3:index==i}" v-for="(it, i) in store?.state?.nav?.homeSwiper" :key="i">
                  <span :class="{active2:index==i}" style="cursor: pointer;" @click.stop="clickChange(i)">{{i+1}}</span>
                  <div :class="{active:index==i}"></div>
                </div>
                <div class="itemRow" @click.stop="clickChange2()">
                  <img :src="rowIMG" alt="">
                </div>
            </div>
          </template>
        </el-carousel-item>
      </el-carousel>
      <div class="container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
    <!-- 推荐商品列表模块 -->
    <div
      ref="mainItem"
      class="recommendContent mainItem"
      :class="{aactive:index==0?true:false,aactive2:index==2?true:false}"
      :style="{backgroundColor:index==0 || index==2?'#fff':'#f2f2f2',top:swiperHeight6,paddingTop:index==0?'4.125rem':'0'}"
      v-for="(item, index) in store?.state?.nav?.RecommendedNavigationList"
      :key="index"
    >
      <div class="header" :style="{'border-bottom':item.navigatorID=='f8685eef721a456a96ad50d03ce47cee'?'none':'.0625rem solid #1f296a'}">
        <div class="kkkk" style="width: 100%;" :style="{'height':index==1?'2.125rem':'3.125rem'}"  v-if="item.navigatorID=='f8685eef721a456a96ad50d03ce47cee'?false:true"></div>
        <div class="text" v-if="item.navigatorID=='f8685eef721a456a96ad50d03ce47cee'?false:true">
          <p>{{item.name}}</p>
          <div class="engIMG">
            <img :src="item.largeIcon" alt="">
          </div>
          <div class="link" v-if="item.navigatorID=='f8685eef721a456a96ad50d03ce47cee'?false:true"></div>
        </div>
      </div>
      <div class="leftQuan" v-show="index==0|| index==2?true:false">
        <img :src="leftquanIMG" alt="">
      </div>
      <div class="rightQuan" v-show="index==0 || index==2?true:false">
        <img :src="rightquanIMG" alt="">
      </div>
      <!-- 四种样式 -->
      <!-- <type1Vue :id='item.navigatorID' :isShow='item.typeSSR=="type=2"?true:false' @typeCallBack='typeCallBack'></type1Vue> -->
      <type8Vue :id='item.navigatorID' :isShow='item.typeSSR=="type=2"?true:false' @typeCallBack='typeCallBack'></type8Vue>
      <type2Vue :id='item.navigatorID' :isShow='item.typeSSR=="type=3"?true:false'></type2Vue>
      <type3Vue :id='item.navigatorID' :isShow='item.typeSSR=="type=4"?true:false'></type3Vue>
      <type4Vue :id='item.navigatorID' :isShow='item.typeSSR=="type=1"?true:false'></type4Vue>
    </div>
  </div>
</template>
<script setup>
import TCPlayer from 'tcplayer.js/dist/tcplayer.v5.0.1.min.js';
import 'tcplayer.js/dist/tcplayer.min.css';

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  getGoodsRecommendList,
  getOneMemberDetail,
  getOneWeixinDetail,
  getArticlePublishList,
} from "@/common/api.js";
import { inject,reactive, ref,computed ,onMounted} from "vue";
import { ElMessage } from "element-plus";
import Common from "@/common/common.js";
import rowIMG from '@/assets/img/main/箭头左.png'
import quanIMG from '@/assets/img/main/圈圈.png'
import leftquanIMG from '@/assets/img/main/left2.png'
import rightquanIMG from '@/assets/img/main/right2.png'
import type1Vue from "@/components/homeNAV/type1.vue";
import type8Vue from "@/components/homeNAV/type8.vue";
import type2Vue from "@/components/homeNAV/type2.vue";
import type3Vue from "@/components/homeNAV/type3.vue";
import type4Vue from "@/components/homeNAV/type4.vue";
import Jump from '@/components/js/index.js'
import { ArrowDownBold,ArrowDown} from "@element-plus/icons-vue";
const store = useStore();
const router = useRouter();
const login = inject('login')
const toPage = () => {
  router.push("/trend");
};
const data = reactive({
  articlePublishList:[],
});
let intervalTime=ref(3000)

store.dispatch('updateCartNumber')

const swiperHeight=ref()
// console.log('可视区域的高度',window.innerHeight);
swiperHeight.value=`${window.innerHeight}px`
// 轮播图高度沾满整个屏幕
const screenheight=ref()
screenheight.value=document.documentElement.clientHeight + 'px'
const swiperHeight2=ref()
swiperHeight2.value=`${window.innerHeight/16}rem`
// console.log('轮播图的高度swiperHeight2111',swiperHeight2.value);
let b=detectBrowser()
// console.log('浏览器',b);
const htmlWidth=ref()//可视区域的宽度
htmlWidth.value=document.documentElement.clientWidth
if(b=='Microsoft Edge'){
   if(htmlWidth.value==1872){
     swiperHeight2.value=`${window.innerHeight/15.6}rem`
   }else if(htmlWidth.value==1912){
    swiperHeight2.value=`${window.innerHeight/15.9333}rem`
   }
 }
window.onresize = () => {
    return (()=>{
        htmlWidth.value=document.documentElement.clientWidth
        if(b=='Microsoft Edge'){
          if(htmlWidth.value==1872){
            swiperHeight2.value=`${window.innerHeight/15.6}rem`
          }else if(htmlWidth.value==1912){
            swiperHeight2.value=`${window.innerHeight/15.9333}rem`
          }
        }
        swiperHeight.value=`${window.innerHeight}px`
        swiperHeight2.value=`${window.innerHeight/16}rem`
        screenheight.value = document.documentElement.clientHeight + 'px'
        // console.log('可视区域的高度',swiperHeight.value);
        // console.log('轮播图的高度swiperHeight2',swiperHeight2.value);
    })
}
// 识别是什么浏览器
function detectBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Chrome")!== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
      return "Google Chrome"; // Chrome
    } else if (userAgent.indexOf("Firefox") !== -1) {
      return "Mozilla Firefox"; // Firefox
    } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edge") === -1) {
      return "Apple Safari"; // Safari
    } else if (userAgent.indexOf("Edg") !== -1) {
      return "Microsoft Edge"; // Edge
    } else {
      return "Unknown browser"; // 其他浏览器...（可根据自己需要确定是否新增其他浏览器的判断）
    }
}

//推荐商品列表
const GoodsRecommendList = () => {
  getGoodsRecommendList(
    {
      // pageNumber:99999999,
      // currentPage:2,
      navigatorID: Common.navIdList.RecommendedNavigationId,
    },
    (res) => {
      if (res.data.header.code == 0) {
        
        // data.GoodsRecommendList = res.data.body.data.rows;
        var GoodsRecommendListDetail = res.data.body.data.rows;
        for (let index = 0; index < GoodsRecommendListDetail.length; index++) {
          var goodsDetail = {};
          goodsDetail.goodsShopShortDescription =
            GoodsRecommendListDetail[index].goodsShopShortDescription.split(
              "，"
            );
          goodsDetail.goodsShopUnitDescription =
            GoodsRecommendListDetail[index].goodsShopUnitDescription.split(
              "，"
            );
          goodsDetail.goodsShopLargerImage =
          GoodsRecommendListDetail[index].goodsShopLargerImage;
          goodsDetail.goodsShopLittleImage =
          GoodsRecommendListDetail[index].goodsShopLittleImage;
          goodsDetail.goodsShopID = GoodsRecommendListDetail[index].goodsShopID;
          goodsDetail.realPrice = GoodsRecommendListDetail[index].realPrice;
          goodsDetail.incomeTotal = GoodsRecommendListDetail[index].incomeTotal;
          goodsDetail.highthMin = GoodsRecommendListDetail[index].highthMin;
          goodsDetail.highthMax = GoodsRecommendListDetail[index].highthMax;
          data.GoodsRecommendList.push(goodsDetail);
          // console.log(data.GoodsRecommendList)
        }
      
      } else {
        ElMessage({
          message: res.data.header.msg,
          type: "error",
        });
      }
    }
  );
};
//获取会员详细信息
const OneMemberDetail = () => {
  getOneMemberDetail({}, (res) => {
    // console.log()
    if (res.data.header.code == 0) {
      data.email = res.data.body.email;
    }
  });
};

// const getArticlePublishListSSR=(val)=>{
//   store?.state?.nav?.RecommendedNavigationList.forEach(it => {
//     getArticlePublishList(it.navigatorID)
//   });
//   getArticlePublishList({
//     companyID:Common.companyID,
//     navigatorID:val,
//     contentType:99,
//     pageNumber:99,
//     sortTypeTime:2,
//     isValid:1,
//   },res=>{
//     this.articlePublishList=res.data.body.data.rows
//   })
// }
// 手动切换轮播图
const carousel=ref()
const clickChange=(index)=>{
  carousel.value.setActiveItem(index)
  // console.log(carousel.value);
}
const clickChange2=()=>{
  carousel.value.next()
}

// GoodsRecommendList();
// OneMemberDetail();

// 加载动画
const loading = ref(true)
const svg = `
  <path class="path" d="
    M 30 15
    L 28 17
    M 25.61 25.61
    A 15 15, 0, 0, 1, 15 30
    A 15 15, 0, 1, 1, 27.99 7.5
    L 15 15
" style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
`
const typeCallBack=(val)=>{
  // console.log('加载状态',val);
  loading.value=val
}
const bannerIMG=ref()
const bb=ref()
// 超过banner图
const rollPassBanner=()=>{
  // console.log(bannerIMG.value[0].height);
  store.commit('user/setIsRoll',883);

}
const mainItem=ref()
const swiperHeight6=ref()
swiperHeight6.value=swiperHeight2.value
// 监听滑动事件
onMounted(()=>{
  window.addEventListener('wheel', handleMouseWheel, {
    passive: false,
  })
})

const handleMouseWheel=(e)=> {
  // console.log('鼠标滚动事件',e);
	if (!window.scrollY) {
		// 禁止页面滚动
		// e.preventDefault()
		
		if (e.wheelDelta) {
			// 判断浏览器IE，谷歌滑轮事件
			if (e.wheelDelta > 0) {
				// 向上滚动时
        if(swiperHeight6.value==0 && store.state.user.appRoll==0){
          swiperHeight6.value=swiperHeight2.value
          store.commit('user/setChangeHeaderColor',false);
        }
			}
			if (e.wheelDelta < 0) {
				// 当滑轮向下滚动
        swiperHeight6.value=0
        store.commit('user/setChangeHeaderColor',true);
			}
		} else if (e.detail) {
			// Firefox滑轮事件
			if (e.detail > 0) {
				// 当滑轮向下滚动时

			}
			if (e.detail < 0) {
				// 当滑轮向上滚动时
				
			}
		}
	}
}

const doScroll = (event) => {
  const scrollHeight = event.target.scrollHeight
  const scrollTop = event.target.scrollTop
  const clientHeight = event.target.clientHeight
 
  if (scrollTop + clientHeight >= scrollHeight) {
    bottom.value = true
  } else {
    bottom.value = false
  }
}
const handleClickJumpArticle=(row)=>{
  // window.location.href = `${row.showURL}`
  window.open(row.showURL)
}
// const video = ref(null)

const carouselChange=(index,oldIndex)=>{
  let swiper = store.state.nav.homeSwiper[index]
  if(swiper.functionType==64){
    // console.log('64',swiper);
    const video = document.querySelector(`#${swiper.name}`)
    let duration = video.duration;
    intervalTime.value=duration*1000;
    video.play()
    // console.log('视频时长：' + duration + '秒');
  }else{
    intervalTime.value=3000;
  }
}
// 2024-1-3
console.log('2024-1-3',store?.state?.nav?.homeSwiper);
let TCPlayerVideo=ref()
const initTCPlayer=()=> {
    // 
  TCPlayerVideo.value = TCPlayer("video2", {
      plugins: {
          ProgressMarker: true, //控制进度条显示
          ContextMenu: {
          mirror: true,
          statistic: true,
          },
          DynamicWatermark: {
          speed: 0.2,
          content: " power system ",
          },
      },
      controlBar: {
          QualitySwitcherMenuButton: true,
      },
      appID:'1254372485',
      autoplay: false,
      language: "zh-CN",
      licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1254372485_1/v_cube.license',
  });
  TCPlayerVideo.value.src("http://file.sdlb.cc/A Shi Ping/项目推进会.mp4");
}
</script>
<style lang="scss" scoped>
$base: .5rem;
.appbox {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

.swiper {
  width: 100%;
  height: 60.125rem;
  // margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  // background-color: aqua;
  z-index: 1;
  img {
    width: 100%;
    height: 60.125rem;
    // height: 100%;
    // height: 768px;
  }
  .itemBox{
    // background-color: #1677ff;
    font-size:1rem;
    z-index:9999;
    display: flex;
    position: absolute;
    bottom: 3.125rem;
    right: 5rem;
    .item{
      color: #fff;
      width: 1.875rem;
      display: flex;
      align-items: center;
      
    }
    .itemRow{
        width: 1.875rem;
        height: .875rem;
        cursor: pointer;
        img{
          transform: rotateY(180deg);
        }
      }
  }
  .bb{
    position: absolute;
    left: 50%;
    bottom: 0;
    text-align: center;
    transform: translateX(-50%);
    color: #94070a;
    font-size: 2.25rem;
    cursor: pointer;
    opacity: 0;
    font-family: icon;
    padding: 0 .625rem;
    animation: bb 2s linear 0s infinite;
    font-weight: bold;
    z-index: 200;
  }
  @keyframes bb {
    0% {
      transform: translate(-50%,0);
      opacity: 0;
    }
    20% {
      transform: translate(-50%,.1875rem);
      opacity: 1;
    }
    80% {
      transform: translate(-50%,.625rem);
      opacity: 1;
    }
    90% {
      transform: translate(-50%,.625rem);
      opacity: 0;
    }
    100% {
      transform: translate(-50%,.625rem);
      opacity: 0;
    }
  }
  

  .container {
    position: absolute;
    left: 50%;
    bottom: 0;
    text-align: center;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 8.125rem;
    // background-color:#000;
      .chevron {
        position: absolute;
        width: $base * 3.5;
        height: $base * 0.8;
        opacity: 0;
        transform: scale(0.3);
        animation: move-chevron 3s ease-out infinite;
      }

      .chevron:first-child {
        animation: move-chevron 3s ease-out 1s infinite;
      }

      .chevron:nth-child(2) {
        animation: move-chevron 3s ease-out 2s infinite;
      }

      .chevron:before,
      .chevron:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      background: #fff;
      }

      .chevron:before {
      left: 0;
      transform: skewY(30deg);
      }

      .chevron:after {
      right: 0;
      width: 50%;
      transform: skewY(-30deg);
      }
  }
  @keyframes move-chevron {
    25% {
      opacity: 0.5;
      }
    33.3% {
      opacity: 0.6;
      transform: translateY($base * 3.8);
    }
    66.6% {
      opacity: 0.7;
      transform: translateY($base * 5.2);
    }
    100% {
      opacity: 0;
      transform: translateY($base * 8) scale(0.5);
    }
  }

}
.showdiv .bb{
  opacity: 1;
}
// .swiper::before{
//     content: "";
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     height: 3.125rem;
//     z-index: 998;
//     // background: linear-gradient(to top,rgba(0,0,0,0.4),transparent);
// }
.active{
  width: 90%;
  height: .0625rem;
  margin-right: .625rem;
  margin-left: .3125rem;
  background-color: #fff;
}
.active2{
  font-size: 1.25rem;
}
.active3{
  width: 4.375rem !important;
  cursor: pointer;
}
// :deep(.el-carousel__indicators--horizontal){
//   right: 0 !important;
//   left: none !important;
//   transform: none;
// }
// ::deeep .el-carousel__item{
//   height: 60.125rem !important;
// }
:deep(.el-carousel__button) {
  width: .625rem;
  height: .625rem;
  border-radius: 50%;
  opacity: 0;
}
:deep(.el-carousel .el-carousel__indicators--horizontal) { 
    height: 3.75rem;
    // background-color: red;
    left: 90%;
    opacity: 0;
  }
// 指示灯未选中的样式
  :deep(.el-carousel__indicator--horizontal .el-carousel__button){
    opacity: 0;
    // width: 80px;
    // height: 8px;
    // border-radius: 31px;
    // background: #FFFFFF80;
  }
// 指示灯选中的样式
  :deep(.el-carousel__indicator--horizontal.is-active .el-carousel__button){
    opacity: 0;
    // width: 80px;
    // height: 8px;
    // border-radius: 31px;
    // background: #fff;
  }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// 推荐商品模块
.recommendContent {
  width: 100%;
  margin: 0 auto;
  padding: 0 13.5%;
  // border-bottom: 1px solid #1f296a;
  // box-shadow: 2px 2px 5px 1px #999;
  position: relative;
  .header{
    width: 100%;
    // height: 80px;
    border-bottom: .0625rem solid #1f296a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // padding-top: 50px;
    .text{
      width: 20.625rem;
      font-size: 2.25rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #1f296a;
      text-align: center;
      position: relative;
      p{
        margin-bottom: .625rem;
        font-weight: 500;
        letter-spacing: .3125rem;
      }
      .link{
        width: 70%;
        height: .125rem;
        background: #1f296a;
        margin:0 15%;
      }
      .engIMG{
        width: 100%;
        position: absolute;
        top: -0.9375rem;
        left: 50%;
        transform: translateX(-50%);
        img{
          width: 100%;

        }
      }
    }
  }
  .leftQuan{
    position: absolute;
    bottom: 0;
    left: 0;
    img{
      width: 13.125rem;
      height: 12.875rem;
      opacity: 0.8;
    }
  }
  .rightQuan{
    position: absolute;
    top: 0;
    right: 0;
    img{
      width: 12.6875rem;
      height: 11.4375rem;
      opacity: 0.8;
    }
  }
}
#themain{
  background-image: url(@/assets/img/main/圈圈.png);
}
// .aactive{
//   background-image: url(@/assets/img/main/圈圈.png);
// }
// .aactive2{
//   background-image: url(@/assets/img/main/圈圈2.png);
// }
.recommendContent:last-child{
  box-shadow: 0px 0px 0px 0px #1f296a;
}
.bc{
  background-color: #fff;
}
.example-showcase .el-loading-mask {
  z-index: 9;
}
.loadingBox{
  position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba($color: #f2f2f2, $alpha: 0.5);

$color-background: #eaecfa;
$color-loader: #1f296a;

body {
  background: $color-background;
}

.loader {
  width: 15.625rem;
  height: 3.125rem;
  line-height: 3.125rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: $color-loader;
  letter-spacing: 0.2em;
  font-size: 1rem;
  
  &::before, &::after {
    content: "";
    display: block;
    width: .9375rem;
    height: .9375rem;
    background: $color-loader;
    position: absolute;
    animation: load .7s infinite alternate ease-in-out;
  }
  
  &::before {
    top: 0;
  }
  
  &::after {
    bottom: 0;
  }
}

@keyframes load {
  0% { left: 0; height: 1.875rem; width: .9375rem }
  50% { height: .5rem; width: 2.5rem }
  100% { left: 14.6875rem; height: 1.875rem; width: .9375rem}
}

}
</style>
<style lang="scss" scoped>
.mainItem{
  width: 100%;
  position: relative;
  left: 0;
  z-index: 2;
  transition: top 0.7s cubic-bezier(0.5,0,0.2,1) 0s;
}
// #video2{
//   width: 100%;
//   height: 100vh;
// }
</style>