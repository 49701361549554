<template>
  <header>
    <div class="main" v-if="otherShow"> 
        <div class="topHeader"></div>
        <div class="contenBox">
            <div class="body" :class="{'active':isActiveSSE,'active2':isActiveSSE}">
                <div class="topBox" v-if="!isActiveSSE">
                    <div class="topItem" v-for="(item,index) in topHeaderList" :key="index" @click="gotoItemHref(item)">
                        <span>{{item.name}}</span>
                    </div>
                </div>
                <div class="botBox">
                    <Header :isActiveSSE='isActiveSSE'></Header>
                </div>
            </div>
            <div class="square" @click="handlerClick">
                <img :src="memoIMG" alt="">
            </div>
        </div>
        
    </div>
  </header>
</template>

<script>
import api from "@/common/api.js";
import {mapState,mapActions} from 'vuex'
import mapIMG from '@/assets/img/main/地图.png'
// import memoIMG from '@/assets/img/main/img100.png'
import memoIMG from '@/assets/img/main/memo99.png'
import Header from "@/components/header/header.vue"
export default {
    props:{
        isShow:{
            type:Boolean,
            default:false,
        },
        otherShow:{
            type:Boolean,
            default:true,
        },
    },
    data () {
        return {
            mapIMG,
            memoIMG,
        }
    },
    components:{
        Header,
    },
    computed:{
        ...mapState({
            topHeaderList:state =>state.nav.topHeaderList,
            isActiveSSE:state =>state.user.changeHeaderColor,
        })
    },
    created(){
    },
    methods:{
        // size每组数组多少个，如：8
        // array需要拆分的数组
        arrayChunk(array, size){
            let data = []
            for (let i = 0; i < array.length; i += size) {
                data.push(array.slice(i, i + size))
            }
            return data
        },
        handlerClick(row){
            let flag=!this.isShow
            this.$emit('openNavPage',flag)
        },
        gotoItemHref(row){
            console.log('我点击了它',row);
            if(row.functionURL){
                this.$router.push(row.functionURL)
            }else{
                
            }
        },

    }
}
</script>

<style scoped lang='scss'>
.main{
    width: 100%;
    min-width: 66.25rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 666;
    .topHeader{
        width: 100%;
        min-width: 66.25rem;
        height:.3125rem;
        // padding: 0 13.5%;
        background-color: #1f296a;
        // background: linear-gradient(to right,#1d5d93 ,#1f296a);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        animation: topHeaderDraw 3s linear;
        transition: all 3s linear;
    }
    // .topHeader::before{
    //     content: '';
    //     top: 0;
    //     right: 0;
    //     width: 0%;
    //     height: 5px;
    //     opacity: 1;
    //     background: linear-gradient(to right,#1d5d93 ,#1f296a);
    //     animation: topHeaderDraw 3s linear;
    // }
    @keyframes topHeaderDraw {
        0%{
            // width:0%;
            transform: translateX(100%);
        }
        100%{
            // width:100%;
            transform: translateX(0%);
        }
    }
    .contenBox{
        width: 100%;
        height:5rem;
        // padding: 0 13.5%;
        // background-color: transparent;
        background:linear-gradient(to bottom,rgba(0,0,0,0.5),transparent);
        position: relative;
        .square{
            width: 4.375rem;
            height: 4.375rem;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 999;
            // background-color: #1f296a;
            // background: linear-gradient(to top, #6510ac, #1635d0);
            // float: right;
            border-radius: 0 0 0 .625rem;
            cursor: pointer;
            img{
                width: 100%;

            }
        }
        .body{
            width: 100%;
            height: 100%;
            padding: 0 13.5%;
            .topBox{
                width: 100%;
                padding-top: .9375rem;
                padding-right: 2.875rem;
                margin-right: 3.75rem;
                display: flex;
                justify-content: end;
                .topItem{
                    font-size: .75rem;
                    color: #f2f2f2;
                    padding: 0 1.25rem;
                    border-right: .0625rem solid #f2f2f2;
                    cursor: pointer;
                }
                .topItem:last-child{
                    border-right: none;
                }
            }
            .botBox{
                width: 100%;
                // height: 80px;

            }
        }
        .active{
            background-color: #fff !important;
            z-index: 665;
            // box-shadow: 2px 2px 5px 1px #f2f2f2;
        }
        .active2{
            // box-shadow: 0px -1px 2px 1px #f6f6f6;
            // border-bottom: .0625rem solid #f2f2f2;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
        }
    }
    
    
}
</style>