import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import nav from './modules/nav'
import user from './modules/user'
import publicSSR from './modules/publicSSR'
import { ElMessage } from "element-plus";
import {
  getCartGoodsTotal,
  getCartGoodsList,
} from "@/common/api.js";
const store = createStore({
  state:{
    //语言
    language:window.localStorage.getItem('languageStateSSR') || true,
    cartNumber:window.localStorage.getItem('cartNumber') || 0,//购物车里面的列表条数
    // 用来传参数--先不用，
    localParams:window.localStorage.getItem('localParams') ||{},
  },
  modules: {
    nav,
    user,
    publicSSR,
  },
  mutations:{
    languageState(state,data){
      state.language=data
      window.localStorage.setItem('languageStateSSR',state.language)
    },
    setCartNumber(state,data){
      state.cartNumber=data
      window.localStorage.setItem('cartNumber',state.cartNumber)
    },
    // 设置参数
    setParams(state,data){
      state.localParams=data
      window.localStorage.setItem('localParams',state.localParams)
    },
  },
  actions:{
    updateCartNumber(context){
      // getCartGoodsTotal({},res=>{
      //   if (res.data.header.code == 0) {
      //     let num=res.data.body.data.goodsNumber || 0
      //     console.log('num10086',num);
      //     context.commit('setCartNumber', num)
      //   }
      // })
      getCartGoodsList({},res=>{
        if (res.data.header.code == 0) {
          let num=res.data.body.data.total || 0
          console.log('num10086',num);
          context.commit('setCartNumber', num)
        }
      })
    },
  },
  plugins: [
    // 本地存储使用的是插件的方式--想将数据保存到本地并且刷新页面不会丢失那么就要用到插件的方式--使用插件缓存对象可以直接缓存不需要转为JSON
    createPersistedState({
      key: 'state',
      paths: ['nav', 'user']
    }),
    createPersistedState({
      key: 'languageStateSSR',
      paths: ['language']
    }),
    createPersistedState({
      key: 'localParams',
      paths: ['localParams']
    }),
  ]
})
export default store