<template>
  <div class="mian" v-if="isShow">
    <div class="leftBox">
        <div class="shortName"></div>
        <div class="name">
            <div class="topName">
                专题
            </div>
            <div class="topName">
                ·栏目
            </div>
            <div class="new">
                NEW
            </div>
            <div class="more">
                查看更多
            </div>
            <div class="engIMG">
                <img :src="lanmuIMG" alt="">
            </div>
        </div>
    </div>
    <div class="rightBox">
        <!-- <div class="item" v-for="(it,index) in dataList" :key="index" :class="{'itemTop':type3IsShow,'item1':index==1?true:false,'item2':index==2?true:false,'item3':index==3?true:false,'item4':index==4?true:false}" @click="handleClickJumpNav({type:'article',...it})">
            <div class="itemImgBox">
                <img :src="it.listImage" alt="">
                <div class="font">
                    {{it.name}}
                </div>
                <div class="moreLink" style="letter-spacing: .1875rem;">
                    <span>查看更多</span>
                    <div class="shuLInk"></div>
                </div>
            </div>
        </div> -->
        <div class="item" v-for="(it,index) in navList" :key="index" :class="{'itemTop':type3IsShow,'item4':true}">
            <div class="itemImgBox" @click="handleClickJumpNav({type:'see',...it})">
                <img :src="it.smallIcon" alt="">
                <div class="font">
                    {{it.name}}
                </div>
                <div class="moreLink" style="letter-spacing: .1875rem;">
                    <span>查看更多</span>
                    <div class="shuLInk"></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import new1IMG from '@/assets/img/main/new1.png'
import new2IMG from '@/assets/img/main/new2.png'
import lanmuIMG from '@/assets/img/main/栏目.png'
import Common from "@/common/common";
import { DArrowRight} from "@element-plus/icons-vue";
import {
  getArticlePublishList,
  getNavigatorList,
} from "@/common/api.js";
import { mapState } from 'vuex';
import Base64 from '@/utils/base64.js' 
export default {
    props: {
        id:{
            type:String,
            required:true,
        },
        isShow:{
            type:Boolean,
            default:true,
        }
    },
    components:{
        DArrowRight,
    },
    data () {
        return {
            new1IMG,
            new2IMG,
            lanmuIMG,
            dataList:[],//置顶
            obj0:{},
            obj1:{},
            dataListIsGood:[],//精选
            navList:[],
        }
    },
    
    watch:{
        id:{
            handler(val){
                this.getArticlePublishListSSR(val)
                // this.getArticlePublishListSSR2(val)
            }
        }
    },
    created(){
        this.getArticlePublishListSSR(this.id)
        // this.getArticlePublishListSSR2(this.id)
        this.getNavigatorList(this.id)
        // let str='2023-09-14'
        // console.log(str.split('-'));
    },
    mounted(){
        
    },
    computed:{
        ...mapState({
            type3IsShow:state=>state.user.type3IsShow
        }),
    },
    methods:{
        // 获取推荐文章列表--置顶
        getArticlePublishListSSR(val){
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeOrderSeq:2,
                isValid:1,
                isTop:1,
            },res=>{
                this.dataList=res.data.body.data.rows
                // res.data.body.data.rows.forEach(it => {
                //     if(it.orderSeq==1){
                //         this.obj0=it
                //     } else if(it.orderSeq==2){
                //         this.obj1=it
                //     } else{
                //         this.dataList.push(it)
                //     }
                // });
            })
        },
        // 获取推荐文章列表--精选
        getArticlePublishListSSR2(val){
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeOrderSeq:2,
                isValid:1,
                isGood:1,
            },res=>{
                this.dataListIsGood=res.data.body.data.rows
                
            })
        },
        // 获取导航
        getNavigatorList(val){
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: val,
                sortTypeOrder: 1
            }, res => {
                this.navList=res.data.body.data.rows
            })
        },
        // 导航跳转
        handleClickJumpNav(row){
            // console.log('当前所点击的对象信息',row);
            if(row.type=='article'){
                this.handleClickJumpArticle(row)
            }else if(row.type=='see'){
                this.toHome(row)
            }
            
        },
        toHome(row) {
            const url = this.$router.resolve({
                name: row.functionURL,
                params:{
                    navigatorID:Base64.encode(row.navigatorID)
                    }
                });
            window.open(url.href, '_blank');
        },
        // 文章跳转
        handleClickJumpArticle(row){
            if(row.functionURL && row.functionURL!="undefined"){
                window.open(row.functionURL, '_blank');
            }else{
                this.$router.push(
                    {
                    path:'/articleDetailsNews',
                    query:{
                        articleID:row.articleID,
                        navigatorID: this.id,
                    }
                    }
                )
            }
        },
    }
}
</script>

<style scoped lang='scss'>
.mian{
    margin-top: 4.625rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5.625rem;
    height: 49.625rem;
    .leftBox{
        // width: 60px;
        padding-right:3.125rem;
        cursor: pointer;
        .shortName{

        }
        .name{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            
            .topName{
                width: 2.1875rem;
                font-size: 1.875rem;
                line-height: 1.9375rem;
                text-align: center;
                font-weight: 500;
                color: #1f296a;
            }
            .new{
                font-size: .625rem;
                color: #747cae;
                width: 2.125rem;
                height: 2.125rem;
                border: .0625rem solid #747cae;
                border-radius: 50%;
                text-align: center;
                line-height: 2.125rem;
                margin-top: 1.875rem;
                margin-bottom: 1.0625rem;
            }
            .more{
                font-size: 1rem;
                font-weight: 500;
                color: #1f296a;
                width: .9375rem;
            }
            .engIMG{
                width: 100%;
                position: absolute;
                top: -30%;
                left: -50%;
            }
        }
    }
    .leftBox:hover{
        .more{
            color: #2878ff;
        }
    }
    .rightBox{
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item{
            width: 33%;
            border-radius: .3125rem;
            overflow: hidden;
            cursor: pointer;
            
            transform: translateY(20%);
            .itemImgBox{
                // width: 90%;
                width: 17.5625rem;
                height: 38rem;
                border-radius: .625rem;
                overflow: hidden;
                position: relative;
                img{
                    width: 100%;
                    // height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                img:hover{
                    transform: scale(1.2);
                }
                .font{
                    writing-mode: vertical-rl;
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    position: absolute;
                    left: 1.5rem;
                    top: 3.125rem;
                    letter-spacing: .3125rem;
                }
                .moreLink{
                    writing-mode: vertical-rl;
                    font-size: 1rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    position: absolute;
                    right: 2.5rem;
                    bottom: 0;
                    display: flex;
                    // flex-direction: column;
                    // justify-content: end;
                    align-items: center;
                    .shuLInk{
                        width: .125rem;
                        height: 1.875rem;
                        margin-top: .3125rem;
                        background-color: #fff;
                        transition: all 200ms linear;
                    }
                }
                .moreLink:hover{
                    // color: #24adf5;
                }
            }
            
        }
        .item:hover{
            .itemImgBox{
                .shuLInk{
                    height: 3.125rem;
                }
            }
        }
        .item1{
            transition: all 200ms linear;
        }
        .item2{
            transition: all 300ms linear;
        }
        .item3{
            transition: all 400ms linear;
        }
        .item4{
            transition: all 500ms linear;
        }
        .itemTop{
            transform: translateY(0%);
            // transition: all 200ms linear;
        }
    }
}

</style>