import { getNavigatorList, getFocusList, getNavigatorSimpleArticleList ,getArticlePublishList} from "../../common/api"
import { ElMessage } from "element-plus";
import Common from "../../common/common";
const arrayChunk=(array, size)=> {
    let data = []
    for (let i = 0; i < array.length; i += size) {
        data.push(array.slice(i, i + size))
    }
    return data
}
export default {
    namespaced: true,
    state() {
        return {
            // 导航信息
            navList: [],
            headerList: [],//导航栏导航列表
            footerList: [],
            homeSwiper: [],
            topHeaderList:[],
            shortCutList:[],
            RecommendedNavigationList:[],
            homeArticlePublishList:[],
            // 因为渲染问题，对底部导航分成两部分
            commonLinkList:[],
            friendshipLinkList:[],
            commonObj:{},
            friendshipObj:{},
            topHeaderListLeft:[],
            topHeaderListRight:[],
            newSwiper:[],
            // 标记是官网导航还是图书馆导航还是招生就业网站的导航
            navCode:0,//默认是官网导航0;图书馆：1；招生就业：2
        }
    },
    mutations: {
        navId(state, data) {
            state.navList = data
        },
        footerId(state, data) {
            state.footerList = { ...data }
        },
        headerId(state, data) {
            state.headerList = { ...data }
        },
        homeId(state, data) {
            state.homeSwiper = data
        },
        newSwiperId(state, data) {
            state.newSwiper = data
        },
        topHeaderID(state,data){
            state.topHeaderList=data
        },
        shortcutNavID(state,data){
            state.shortCutList=data
        },
        RecommendedNavigationId(state,data){
            // console.log(data);
            state.RecommendedNavigationList=data
        },
        setcommonLinkList(state,data){
            state.commonLinkList=data
        },
        setfriendshipLinkList(state,data){
            state.friendshipLinkList=data
        },
        setcommonObj(state,data){
            state.commonObj=data
        },
        setfriendshipObj(state,data){
            state.friendshipObj=data
        },
        settopHeaderListLeft(state,data){
            state.topHeaderListLeft=data
        },
        settopHeaderListRight(state,data){
            state.topHeaderListRight=data
        },
        setNavCode(state,data){
            state.navCode=data
        }
    },
    //这里定义异步操作和提交mutations的方法
    actions: {
        //获取导航列表
        async getNavList(context) {
            for (let key in Common.navIdList) {
                getNavigatorList({
                    siteID: Common.siteID,
                    navigatorID: Common.navIdList[key],
                    sortTypeOrder: 1
                }, res => {
                    let data = []
                    if (res.data.header.code === 0) {
                        data = res.data.body.data.rows
                        if (key !== 'footerId') {
                            if(key =='RecommendedNavigationId'){
                                data.forEach(item => {
                                    item.typeSSR=item.tagCode.split(',')[0]
                                });
                                context.commit(key, data)
                                return
                            }
                            if(key =='topHeaderID'){
                                let li=arrayChunk(data,Math.ceil(data.length/2))
                                context.commit('settopHeaderListLeft', li[0])
                                context.commit('settopHeaderListRight', li[1])
                                context.commit(key, data)
                                return
                            }
                            context.commit(key, data)
                            // console.log('111',data);
                            return
                        }
                        
                        data.forEach(item => {
                            
                            getNavigatorList({
                                siteID: Common.siteID,
                                navigatorID: item.navigatorID,
                                sortTypeOrder: 1,
                                pageNumber:9999,
                            }, res => {
                                if(item.navigatorID=='6c3a994b6fa54b499d655eacd11acb5c'){
                                    context.commit('setcommonLinkList', res.data.body.data.rows)
                                    context.commit('setcommonObj',item)
                                }
                                if(item.navigatorID=='bd5eda20408e4f06b44ff90f0b00ed5f'){
                                    context.commit('setfriendshipLinkList', res.data.body.data.rows)
                                    context.commit('setfriendshipObj',item)
                                }
                                item.rows = res.data.body.data.rows
                                item.rows.forEach((it)=>{
                                    if(it.name.length>20){
                                        it.name=Common.getNewline(it.name,20)
                                        it.shortName=Common.getNewline(it.shortName,20)
                                        // console.log(Common.getNewline(it.name,20));
                                    }
                                })
                                context.commit(key, data)
                                // console.log('222333',data);
                            })
                        });
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取swiper
        getSwiper(context) {
            for (let key in Common.swiper) {
                getFocusList({
                    siteID: Common.siteID,
                    focusID: Common.swiper[key],
                    sortTypeOrder: 1
                }, res => {
                    if (res.data.header.code === 0) {
                        context.commit(key, res.data.body.data.rows)
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取newSwiper
        getNewSwiper(context) {
            for (let key in Common.newSwiper) {
                getFocusList({
                    siteID: Common.siteID,
                    focusID: Common.newSwiper[key],
                    sortTypeOrder: 1
                }, res => {
                    if (res.data.header.code === 0) {
                        context.commit(key, res.data.body.data.rows)
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取导航列表
        getNavList2(context,shortName) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.headerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((it)=>{
                        if(it.name=='我的账户'){
                            it.name=`${it.name}${shortName}`
                            it.shortName=`${it.shortName}${shortName}`
                        }
                    })
                    // console.log('data',data);
                    context.commit('headerId', data)
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        //获取导航列表
        getNavList3(context,) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.footerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((it)=>{
                        if(it.name.length>10){
                            Common.getNewline(item.name,16)
                            // console.log(Common.getNewline(item.name,16));
                        }
                    })
                    // console.log('data222',data);
                    context.commit('footerId', data)
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        // 获取图书馆导航列表并赋值给headerList
        getLibarayNavList(context) {
            getNavigatorList({
                siteID: Common.libaraySiteID,
                navigatorID: Common.libarayNavID,
                sortTypeOrder: 1
            }, res => {
                if(res.data.header.code==0){
                    context.commit('headerId', res.data.body.data.rows)
                    context.commit('setNavCode',1)
                }else{
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        // 获取官网导航列表并赋值给headerList
        getHeaderNavList(context) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.headerId,
                sortTypeOrder: 1
            }, res => {
                if(res.data.header.code==0){
                    context.commit('headerId', res.data.body.data.rows)
                    context.commit('setNavCode',0)
                }else{
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
    }
}