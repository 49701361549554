<template>
  <div class="mian" v-if="isShow">
    <div class="topIMG">
        <img :src="obj1.largeIcon" alt="">
    </div>
    <div class="box">
        <div class="topBox">
            <div class="left">
                <div class="leftText" @click.stop="handleClickJump(obj0)" :class="{'leftTextTop':type4IsShow2}">
                    <div class="iconIMG">
                        <img :src="obj0.largeIcon" alt="">
                    </div>
                    <span class="title">{{obj0.name}}</span>
                    <span style="font-size: 1.5rem;font-weight: 500;color: #999999;">/</span>
                    <span class="shortName">{{obj0.shortName}}</span>
                </div>
                <div class="bbox">
                    <div class="link" :class="{'linkTop':type4IsShow2}"></div>
                    <div class="more" @click.stop="handleClickJump(obj0)" :class="{'moreTop':type4IsShow2}">
                        <span>查看更多</span>
                        <span> >> </span>
                    </div>
                </div>
                
            </div>
            <div class="right" :class="{'rightLeft':type4IsShow2}">
                <div class="redBox">
                    <div class="title">
                        {{obj1.name}}
                    </div>
                    <div class="linkSSR"></div>
                    <div class="content">
                        {{obj1.description}}
                    </div>
                </div>
            </div>
        </div>
        <div class="contentBox">
            <div class="item" v-for="(it,index) in dataList" :key="index">
                <div class="itemImg">
                    <!-- <img :src="it.largeIcon" alt=""> -->
                    <svg v-if="it.navigatorID=='e31bd9fd622a4a5db6bca4bc5fae3d1d'" :width="63*(htmlWidth/1920)" :height="68*(htmlWidth/1920)" viewBox="0 0 63 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="path1" d="M6.5 14.5H3C1.89543 14.5 1 15.3954 1 16.5V22.5C1 23.6046 1.89543 24.5 3 24.5H6.5M55.5 14.5H59.5C60.6046 14.5 61.5 15.3954 61.5 16.5V22.5C61.5 23.6046 60.6046 24.5 59.5 24.5H55.5M12 49.5H50.5" stroke="black" stroke-width="2"/>
                    <path id="path2" d="M7.5 43.5H14M55 43.5H48.5M48.5 43.5C48.5 42.8749 48.5 36.3068 48.5 31C48.5 28.7908 46.7091 27 44.5 27H18C15.7909 27 14 28.7909 14 31V43.5M48.5 43.5H14M25 7H37" stroke="black" stroke-width="2" style="width: 3.9375rem;height: 4.25rem;"/>
                    <path id="path3" d="M8.5 49.5H13C14.1046 49.5 15 50.3954 15 51.5V55.5C15 56.6046 14.1046 57.5 13 57.5H9C7.89543 57.5 7 56.6046 7 55.5V49.5H6C4.89543 49.5 4 48.6046 4 47.5V45.5C4 44.3954 4.89543 43.5 6 43.5H7V31V7.33333C7 6.52593 7.47625 5.79686 8.23272 5.51463C16.7865 2.3233 35.8328 -1.44364 53.7609 5.50196C54.5138 5.79364 55 6.52593 55 7.33333V43.5H56C57.1046 43.5 58 44.3954 58 45.5V47.5C58 48.6046 57.1046 49.5 56 49.5H55V55.5C55 56.6046 54.1046 57.5 53 57.5H49C47.8954 57.5 47 56.6046 47 55.5V51.5C47 50.3954 47.8954 49.5 49 49.5H53.5" stroke="black" stroke-width="2"/>
                    <path id="path4" d="M40 34C40 35.1046 40.8954 36 42 36C43.1046 36 44 35.1046 44 34C44 32.8954 43.1046 32 42 32C40.8954 32 40 32.8954 40 34Z" stroke="black" stroke-width="2"/>
                    <path id="path5" d="M18 34C18 35.1046 18.8954 36 20 36C21.1046 36 22 35.1046 22 34C22 32.8954 21.1046 32 20 32C18.8954 32 18 32.8954 18 34Z" stroke="black" stroke-width="2"/>
                    <path id="path6" d="M31 11H16C14.8954 11 14 11.8954 14 13V20.5385C14 21.643 14.8954 22.5385 16 22.5385H46C47.1046 22.5385 48 21.643 48 20.5385V13C48 11.8954 47.1046 11 46 11H31ZM31 11V23" stroke="black" stroke-width="2"/>
                    </svg>

                    <svg v-if="it.navigatorID=='4bf66a8a2c79425596a7ed7af08f2a88'" :width="64*(htmlWidth/1920)" :height="60*(htmlWidth/1920)" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="jz1" d="M20.5 17C24.1655 19.2639 33.9698 22.4333 43.5 17" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz2" d="M14 35.5833V42H49V35" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz3" d="M14.1538 42H11V46H52V42H49.5522" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz4" d="M14 46V63" stroke="#383484" stroke-width="2"/>

                    <path id="jz5" d="M29 46V63" stroke="#383484"/>

                    <path id="jz6" d="M45 46V63" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz7" d="M18 46V63" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz8" d="M34 46V63" stroke="#383484"/>

                    <path id="jz9" d="M49 46V63" stroke="#383484" stroke-width="2"/>

                    <path id="jz10" d="M25 46V63" stroke="#383484" stroke-width="4"/>

                    <path id="jz11" d="M38 46V63" stroke="#383484" stroke-width="4"/>

                    <path id="jz12" d="M6 6H32.1954H59V1H6V6Z" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz13" d="M8 6C9.03376 6.4 11.1013 7.56 11.1013 9H52.6582C53.4852 8 55.5114 6 57 6" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz14" d="M50.5442 24.5196C50.5442 23.3005 49.0536 21.7229 46.9247 22.0814C43.3052 22.691 43.3052 28.7865 47.5279 30.0056C51.7507 31.2247 55.3702 27.5674 55.3702 23.3005C55.3702 19.0337 51.1974 15.9859 47.5279 15.9859C43.3052 15.9859 39.6103 19.0337 39.0824 23.3005C38.5546 27.5674 40.2889 36.1011 52.354 34.882C64.419 33.6629 63.8157 19.6432 62.006 15.9859C60.1962 12.3286 55.3702 8.52876 51.5217 9.0478" stroke="#383484" stroke-width="1.5"/>

                    <path id="jz15" d="M13.4558 24.5196C13.4558 23.3005 14.9464 21.7229 17.0753 22.0814C20.6948 22.691 20.6948 28.7865 16.4721 30.0056C12.2493 31.2247 8.62979 27.5674 8.62979 23.3005C8.62979 19.0337 12.8026 15.9859 16.4721 15.9859C20.6948 15.9859 24.3897 19.0337 24.9176 23.3005C25.4454 27.5674 23.7111 36.1011 11.646 34.882C-0.418977 33.6629 0.184276 19.6432 1.99403 15.9859C3.80378 12.3286 7.78261 8.52876 13 9.0478" stroke="#383484" stroke-width="1.5"/>
                    <path id="jz16" d="M24.7561 28H39.7805M43 33H21" stroke="#383484" stroke-width="1.5"/>
                    </svg>

                    <svg v-if="it.navigatorID=='72ef83a5e264415da6aa69c117c3522c'" :width="61*(htmlWidth/1920)" :height="62*(htmlWidth/1920)" viewBox="0 0 61 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="xs1" d="M21.6293 43V46.3289V49.6579M21.6293 49.6579C17.7932 50.8684 9.15182 53.6526 5.27532 55.1053C1.39882 56.5579 0.833494 62.5702 1.0354 65.3947M21.6293 49.6579C24.0862 51.2105 31 53.3842 39 49.6579" stroke="black" stroke-width="2" style="width: 3.8125rem;height: 3.875rem;"/>
                    <path id="xs2" d="M39 43V46.3289V49.6579C40.4724 50.1225 42.6528 50.819 45 51.5831M59.5939 65.3947C59.7958 62.5702 59.2305 56.5579 55.354 55.1053C52.9654 54.2102 48.7678 52.8096 45 51.5831M45 51.5831C40 56.6108 27.1 63.6496 15.5 51.5831" stroke="black" stroke-width="2"/>
                    <path id="xs3" d="M6 33.75C7.61749 33.3472 10.7311 31.9375 10.2459 29.5208V21.6667H12.0656M14.4918 41V21.6667H12.0656M10.2459 34.9583C10.8525 36.1667 11.459 38.7042 9.03279 39.1875M12.0656 12V21.6667" stroke="black" stroke-width="2"/>
                    <path id="xs4" d="M17.5 35.5C17.5 39.5 22 45.8338 32 45.0338C40.5 45.0338 43.5 37.5338 43.5 34.5338C43.5 32.1338 43.5 24 43.5 20C39.8333 17.3333 29.6 13.6 18 20" stroke="black" stroke-width="2"/>
                    <path id="xs5" d="M17.5 35.5V14M17.5 14L8 10.5L31 2L53.5 10.5L43.5 14M17.5 14C27.9 7.6 39.3333 11.3333 43.5 14M43.5 14V20" stroke="black" stroke-width="2"/>
                    </svg>

                    <!-- f06da9a3eeef4f11bf65e74a3a5dbe3b -->
                    <svg v-if="it.navigatorID=='f06da9a3eeef4f11bf65e74a3a5dbe3b'" :width="53*(htmlWidth/1920)" :height="85*(htmlWidth/1920)" viewBox="0 0 53 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="sb1" d="M11.5 48C3.62883 44.5564 -5.21035 22.7199 13.556 14.8739C14.0655 14.6609 14.6572 14.9192 14.8511 15.4363L16.8033 20.6423C17.0916 21.4111 16.3216 22.3863 15.5596 22.6921C14.2797 23.2058 12.7318 24.5437 11.5 27.5M11.5 36C12 40.5 17 45 24.5 42.5" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    <path id="sb2" d="M24.5 39.5667L21.9815 40.2383C21.4419 40.3822 20.8891 40.0563 20.7536 39.5146L20.2388 37.4551C20.1063 36.9252 20.4235 36.3871 20.9513 36.2463L34.0185 32.7617C34.5581 32.6178 35.1109 32.9437 35.2464 33.4854L35.7612 35.5449C35.8937 36.0748 35.5765 36.6129 35.0487 36.7537L30 38.1M24.5 39.5667L27 48M24.5 39.5667L30 38.1M27 48H8.5C7.29947 48 5.018 48.7296 4.57542 51.5029C4.48838 52.0482 4.05228 52.5 3.5 52.5H0H39M27 48C27.7785 48 29.7691 48 32.5 48M43.5 52.5H39M39 52.5C39.3333 51 39 48 35 48C34.114 48 33.277 48 32.5 48M32.5 48L30 38.1" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    <path id="sb3" d="M15.0685 6.9935L14.5591 7.16288C14.2214 7.27519 13.8495 7.19953 13.5824 6.96423L12.3015 5.8354C11.7235 5.32607 11.9143 4.38195 12.6447 4.13703L20.6821 1.44193C21.3299 1.22469 22 1.70673 22 2.39004V3.96722C22 4.39791 21.7242 4.78023 21.3155 4.91613L19.8904 5.39004M15.0685 6.9935L15.5205 10M15.0685 6.9935L19.8904 5.39004M19.8904 5.39004L21.3973 7.93443" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    <path id="sb4" d="M32 23V6C32 5.44772 32.4477 5 33 5H45C45.5523 5 46 5.44771 46 6V23C46 23.5523 45.5523 24 45 24H33C32.4477 24 32 23.5523 32 23Z" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    <path id="sb5" d="M27.6176 21L24.0936 9.01841C23.7708 7.92059 22.5943 7.317 21.5142 7.69502L15.3434 9.85482C14.3183 10.2136 13.7669 11.3249 14.1012 12.3582L18.0294 24.5M27.6176 21L27.9722 22.2056C28.2693 23.2155 27.7327 24.2827 26.7449 24.6466L20.9535 26.7803C19.8875 27.173 18.7089 26.6001 18.3592 25.5193L18.0294 24.5M27.6176 21C25.2647 20.8333 20.0529 21.3 18.0294 24.5M21.2857 26.5L22.2239 29.9767C22.3737 30.5319 22.9599 30.8467 23.5056 30.6648L26.105 29.7983C26.6075 29.6308 26.8923 29.1008 26.7543 28.5894C26.6382 28.1591 26.5015 27.6528 26.3929 27.25C26.1558 26.3713 25.7857 25 25.7857 25" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    <path id="sb6" d="M7 32C7 34.2091 8.79086 36 11 36C13.2091 36 15 34.2091 15 32C15 29.7909 13.2091 28 11 28C8.79086 28 7 29.7909 7 32Z" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    <path id="sb7" d="M35 11H42.5M35 17H42.5" stroke="black" :stroke-width="2*(htmlWidth/1920)"/>
                    </svg>

                </div>
                <div class="text">
                    {{it.name}}
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import new1IMG from '@/assets/img/main/new1.png'
import new2IMG from '@/assets/img/main/new2.png'
import Common from "@/common/common";
import { DArrowRight} from "@element-plus/icons-vue";
import {
  getArticlePublishList,
  getNavigatorList,
} from "@/common/api.js";
import { mapState } from 'vuex';
export default {
    props: {
        id:{
            type:String,
            required:true,
        },
        isShow:{
            type:Boolean,
            default:true,
        }
    },
    components:{
        DArrowRight,
    },
    data () {
        return {
            new1IMG,
            new2IMG,
            dataList:[],//置顶
            obj0:{},
            obj1:{},
            dataListIsGood:[],//精选
            navList:[],
            htmlWidth:1920,
        }
    },
    
    watch:{
        id:{
            handler(val){
                // this.getArticlePublishListSSR(val)
                // this.getArticlePublishListSSR2(val)
                this.getNavigatorList()
            }
        }
    },
    created(){
        // this.getArticlePublishListSSR(this.id)
        // this.getArticlePublishListSSR2(this.id)
        this.getNavigatorList()
        // let str='2023-09-14'
        // console.log(str.split('-'));
    },
    mounted(){
        this.htmlWidth=document.documentElement.clientWidth
        window.onresize = () => {
          console.log('document.documentElement.style',document.documentElement.clientWidth);
          this.htmlWidth=document.documentElement.clientWidth
        }
        console.log('当前页面宽度',this.htmlWidth);
    },
    computed:{
        ...mapState({
            type4IsShow1:state=>state.user.type4IsShow1,
            type4IsShow2:state=>state.user.type4IsShow2
        }),
    },
    methods:{
        // 获取推荐文章列表--置顶
        getArticlePublishListSSR(val){
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeOrderSeq:2,
                isValid:1,
                isTop:1,
            },res=>{
                // this.dataList=res.data.body.data.rows
                res.data.body.data.rows.forEach(it => {
                    if(it.orderSeq==1){
                        this.obj0=it
                    } else if(it.orderSeq==2){
                        this.obj1=it
                    } else{
                        this.dataList.push(it)
                    }

                });
            })
        },
        // 获取推荐文章列表--精选
        getArticlePublishListSSR2(val){
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeOrderSeq:2,
                isValid:1,
                isGood:1,
            },res=>{
                this.dataListIsGood=res.data.body.data.rows
                
            })
        },
        // 获取导航
        getNavigatorList(){
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: this.id,
                sortTypeOrder: 1
            }, res => {
                // this.navList=res.data.body.data.rows
                res.data.body.data.rows.forEach((it)=>{
                    if(it.navigatorID=='97ca3357d15841b2a4900a562064a972'){
                        // 校园风光
                        this.obj0=it
                    }else if(it.navigatorID=='06baabcd5e854c95bddf0eef0b4ecc28'){
                        // 内容简介
                        this.obj1=it
                    }else{
                        // 展示列表
                        this.dataList.push(it)
                    }
                })
            })
        },
        // 跳转
        handleClickJump(row){
            this.$router.push(
                {
                    path:row.functionURL,
                    query:{
                        navigatorID:'d1a374b7821c4669b39fd5ec7862f22c'
                    }
                }
            )
        },
        
    }
}
</script>

<style scoped lang='scss'>
.mian{
    margin-top: 1.25rem;
    width: 100%;
    .topIMG{
        width: 100%;
        height: 25rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .box{
        width: 100%;
        .topBox{
            width: 100%;
            // height: 197px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                width: 50%;
                .leftText{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    transform:translateY(200%);
                    cursor: pointer;
                    .iconIMG{
                        margin-right:.8125rem;
                        img{
                            width: 1.3125rem;
                            height: 1.9375rem;
                        }
                    }
                    .title{
                        font-size: 1.875rem;
                        font-weight: 500;
                        color: #B33422;
                        margin-right:.5rem;
                    }
                    .shortName{
                        margin-left:.3125rem;
                        font-size: 1.125rem;
                        font-weight: 500;
                        font-style: italic;
                        color: #999999;
                    }
                }
                .leftTextTop{
                    transform:translateY(0%);
                    transition: all 600ms 0ms linear;
                    animation: floatShow 600ms 0ms linear;
                }
                .bbox{
                    width: 33%;
                    .link{
                        width: 0%;
                        height: .0625rem;
                        margin:1.25rem 0;
                        background: #B33422;
                        position: relative;
                    }
                    .more{
                        float: right;
                        font-size: 1rem;
                        font-weight: 500;
                        color: #444444;
                        cursor: pointer;
                        transform:translateY(200%);
                        opacity: 0;
                    }
                    .more:hover{
                        color: #2878ff;
                    }
                    .linkTop{
                        // transform:translateY(0%);
                        width: 100%;
                        transition: all 400ms 600ms linear;
                        // animation: floatShow 600ms 300ms linear;
                    }
                    .moreTop{
                        transform:translateY(0%);
                        opacity: 1;
                        transition: all 600ms 300ms linear;
                        animation: floatShow 600ms 300ms linear;
                    }
                }
                
            }
            .right{
                width: 50%;
                // position: relative;
                transform:translateX(150%);
                .redBox{
                    // position: absolute;
                    // top: -35px;
                    transform: translateY(-2.1875rem);
                    width: 39.9375rem;
                    height: 14.4375rem;
                    padding: 3.125rem;
                    background: #4c6cbb;
                    .title{
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 2.125rem;
                    }
                    .linkSSR{
                        width: 19rem;
                        height: .0625rem;
                        margin: .8125rem 0;
                        background: #FFFFFF;
                    }
                    .content{
                        font-size: 1.125rem;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 2.125rem;
                        -webkit-line-clamp: 2;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                        display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                        -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                        overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                        text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                    }
                }
                
            }
            .rightLeft{
                transform:translateX(0%);
                transition: all 600ms linear;
                animation: floatShow 600ms linear;
            }
        }
        .contentBox{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            padding: 4.375rem 0;
            .item{
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                .itemImg{
                    // width: 60px;
                    height: 3.75rem;
                    margin: 0 auto;
                    // img{
                    //     // width: 100%;
                    //     height: 100%;
                    //     // object-fit: cover;
                    //     margin: 0 auto;
                    // }
                    path{
                        /* 颜色 */
                        stroke: #1f296a !important;
                        /* 圆角 */
                        /* stroke-linecap: round; */
                        /* 宽度 */
                        stroke-width: .125rem;
                        stroke-dashoffset: 0;
                        stroke-dasharray:0;
                    }
                }
                // .itemImg:hover{
                //     transform: translateY(-2px);
                //     transition: all 0.5s linear;
                // }
                .itemImg:hover img{
                    
                }
                @keyframes shake{
                    0%{transform: rotate(0deg);}
                    20%{transform: rotate(20deg);}
                    40%{transform: rotate(-20deg);}
                    60%{transform: rotate(20deg);}
                    80%{transform: rotate(-20deg);}
                    100%{transform: rotate(0deg);}
                }
                
                .text{
                    margin-top: 1.25rem;
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: #1f296a;
                }
            }
            .item:hover{
                .itemImg{
                    svg{
                        #path1{
                            stroke-dashoffset: 78;
                            stroke-dasharray:78;
                            animation: draw 0.2s linear 0s forwards;
                        }
                        #path2{
                            stroke-dashoffset: 124;stroke-dasharray:124;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #path3{
                            stroke-dashoffset: 200;stroke-dasharray:200;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #path4{
                            stroke-dashoffset: 13;stroke-dasharray:13;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #path5{
                            stroke-dashoffset: 13;stroke-dasharray:13;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #path6{
                            stroke-dashoffset: 100;stroke-dasharray:100;
                            animation: draw 0.2s linear 1s forwards;
                        }
                        #path7{
                            stroke-dashoffset: 21;stroke-dasharray:21;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #path8{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #path9{
                            stroke-dashoffset: 162;stroke-dasharray:162;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #path10{
                            stroke-dashoffset: 115;stroke-dasharray:115;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #path11{
                            stroke-dashoffset: 107;stroke-dasharray:107;
                            animation: draw 0.2s linear 1s forwards;
                        }
                        #path12{
                            stroke-dashoffset: 37;stroke-dasharray:37;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz1{
                            stroke-dashoffset: 24;stroke-dasharray:24;
                            animation: draw 0.2s linear 0s forwards;
                        }
                        #jz2{
                            stroke-dashoffset: 48;stroke-dasharray:48;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz3{
                            stroke-dashoffset: 55;stroke-dasharray:55;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #jz4{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #jz5{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #jz6{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 1s forwards;
                        }
                        #jz7{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz8{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #jz9{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #jz10{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #jz11{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 1s forwards;
                        }
                        #jz12{
                            stroke-dashoffset: 116;stroke-dasharray:116;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz13{
                            stroke-dashoffset: 51;stroke-dasharray:51;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz14{
                            stroke-dashoffset: 110;stroke-dasharray:110;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz15{
                            stroke-dashoffset: 110;stroke-dasharray:110;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #jz16{
                            stroke-dashoffset: 37;stroke-dasharray:37;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #map1{
                            stroke-dashoffset: 355;stroke-dasharray:355;
                            animation: draw 0.2s linear 0s forwards;
                        }
                        #map2{
                            stroke-dashoffset: 232;stroke-dasharray:232;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #map3{
                            stroke-dashoffset: 148;stroke-dasharray:148;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #map4{
                            stroke-dashoffset: 17;stroke-dasharray:17;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #map5{
                            stroke-dashoffset: 34;stroke-dasharray:34;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #xs1{
                            stroke-dashoffset: 54;stroke-dasharray:54;
                            animation: draw 0.3s linear 0.1s forwards;
                        }
                        #xs2{
                            stroke-dashoffset: 69;stroke-dasharray:69;
                            animation: draw 0.3s linear 0.3s forwards;
                        }
                        #xs3{
                            stroke-dashoffset: 53;stroke-dasharray:53;
                            animation: draw 0.3s linear 0.6s forwards;
                        }
                        #xs4{
                            stroke-dashoffset: 78;stroke-dasharray:78;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #xs5{
                            stroke-dashoffset: 124;stroke-dasharray:124;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #sb1{
                            stroke-dashoffset: 74;stroke-dasharray:74;
                            animation: draw 0.2s linear 0s forwards;
                        }
                        #sb2{
                            stroke-dashoffset: 145;stroke-dasharray:145;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #sb3{
                            stroke-dashoffset: 31;stroke-dasharray:31;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #sb4{
                            stroke-dashoffset: 64;stroke-dasharray:64;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        #sb5{
                            stroke-dashoffset: 77;stroke-dasharray:77;
                            animation: draw 0.2s linear 0.8s forwards;
                        }
                        #sb6{
                            stroke-dashoffset: 25;stroke-dasharray:25;
                            animation: draw 0.2s linear 1s forwards;
                        }
                        #sb7{
                            stroke-dashoffset: 15;stroke-dasharray:15;
                            animation: draw 0.2s linear 0.2s forwards;
                        }
                        #sb8{
                            stroke-dashoffset: 77;stroke-dasharray:77;
                            animation: draw 0.2s linear 0.4s forwards;
                        }
                        #sb9{
                            stroke-dashoffset: 25;stroke-dasharray:25;
                            animation: draw 0.2s linear 0.6s forwards;
                        }
                        @keyframes draw {
                            to{
                                stroke-dashoffset: 0;
                            }
                        }
                    }
                }
                
                // .text{
                //     // color: #2878ff;
                //     animation: beat 0.7s 1;
                //     transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
                // }
                // .itemImg{
                //     animation: beat 0.7s 1;
                //     transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
                // }
            }
        }
    }
}
@keyframes floatShow
{
    0%   {opacity: 0;}
    25%  {opacity: 0.25;}
    50%  {opacity: 0.5;}
    75%  {opacity: 0.6;}
    90%  {opacity: 0.8;}
    100% {opacity: 1;}
}
@keyframes beat {
    from, to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    25% {
        -webkit-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
    }
    50% {
        -webkit-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
    }
    75% {
        -webkit-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
    }
    from, to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    25% {
        -webkit-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
    }
    50% {
        -webkit-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
    }
    75% {
        -webkit-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
    }
}

</style>
