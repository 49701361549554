const routers = [
    {
        path: '/articleList',
        name: '文章列表',
        component: () => import('@/views/huoxingViews/article/articleList.vue'),
        meta:{
            title:'鲁班学院文章列表',
            content:{
              keywords:'鲁班学院文章列表,鲁班学院官网文章列表,枣庄,枣庄鲁班学院文章列表,枣庄鲁班学院官网文章列表,鲁班职业学院文章列表,鲁班职业学院官网文章列表,',
              description:'鲁班学院文章列表'
            }
        },
    },
    {
        path: '/navListNews',
        name: '导航列表news',
        component: () => import('@/views/huoxingViews/article/navListNews.vue'),
        meta:{
            title:'鲁班学院导航列表news',
            content:{
              keywords:'鲁班学院导航列表news,鲁班学院官网导航列表news,枣庄,枣庄鲁班学院导航列表news,枣庄鲁班学院官网导航列表news,鲁班职业学院导航列表news,鲁班职业学院官网导航列表news,',
              description:'鲁班学院导航列表news'
            }
        },
    },
    {
        path: '/articleDetail',
        name: '文章详情',
        component: () => import('@/views/huoxingViews/article/articleDetails.vue'),
        meta:{
            title:'鲁班学院文章详情',
            content:{
              keywords:'鲁班学院文章详情,鲁班学院官网文章详情,枣庄,枣庄鲁班学院文章详情,枣庄鲁班学院官网文章详情,鲁班职业学院文章详情,鲁班职业学院官网文章详情,',
              description:'鲁班学院文章详情'
            }
        },
    },
    {
        path: '/articleDetailsNews',
        name: '新闻正文',
        component: () => import('@/views/huoxingViews/article/articleDetailsNews.vue'),
        meta:{
            title:'鲁班学院新闻正文',
            content:{
              keywords:'鲁班学院新闻正文,鲁班学院官网新闻正文,枣庄,枣庄鲁班学院新闻正文,枣庄鲁班学院官网新闻正文,鲁班职业学院新闻正文,鲁班职业学院官网新闻正文,',
              description:'鲁班学院新闻正文'
            }
        },
    },
    {
        path: '/articleDetailSSR',//此文章详情有banner
        name: '文章详情',
        component: () => import('@/views/huoxingViews/article/articleDetailSSR.vue'),
        meta:{
            title:'鲁班学院文章详情',
            content:{
              keywords:'鲁班学院文章详情,鲁班学院官网文章详情,枣庄,枣庄鲁班学院文章详情,枣庄鲁班学院官网文章详情,鲁班职业学院文章详情,鲁班职业学院官网文章详情,',
              description:'鲁班学院文章详情'
            }
        },
    },
    {
        path: '/campusScenery',
        name: '校园风光',
        component: () => import('@/views/huoxingViews/campusServices/campusScenery.vue'),
        meta:{
            title:'鲁班学院校园风光',
            content:{
              keywords:'鲁班学院校园风光,鲁班学院官网校园风光,枣庄,枣庄鲁班学院校园风光,枣庄鲁班学院官网校园风光,鲁班职业学院校园风光,鲁班职业学院官网校园风光,',
              description:'鲁班学院校园风光'
            }
        },
    },
    {
        path: '/abountUsHome',
        name: '学院概况',
        component: () => import('@/views/huoxingViews/abountUs/home.vue'),
        meta:{
            title:'鲁班学院学院概况',
            content:{
              keywords:'鲁班学院学院概况,鲁班学院官网学院概况,枣庄,枣庄鲁班学院学院概况,枣庄鲁班学院官网学院概况,鲁班职业学院学院概况,鲁班职业学院官网学院概况,',
              description:'鲁班学院学院概况'
            }
        },
    },
    // {
    //     path: '/collegeIntroduction',
    //     name: '学院简介',
    //     component: () => import('@/views/huoxingViews/abountUs/collegeIntroduction.vue')
    // },
    // {
    //     path: '/schoolEmblemAndMotto',
    //     name: '校徽校训',
    //     component: () => import('@/views/huoxingViews/abountUs/schoolEmblemAndMotto.vue')
    // },
    // {
    //     path: '/collegeConstitution',
    //     name: '学院章程',
    //     component: () => import('@/views/huoxingViews/abountUs/collegeConstitution.vue')
    // },
    // {
    //     path: '/currentLeader',
    //     name: '现任领导',
    //     component: () => import('@/views/huoxingViews/abountUs/currentLeader.vue')
    // },
    // {
    //     path: '/publicInformation',
    //     name: '信息公开',
    //     component: () => import('@/views/huoxingViews/abountUs/publicInformation.vue')
    // },
    {
        path: '/traffic',
        name: '交通引导',
        component: () => import('@/views/huoxingViews/abountUs/traffic.vue'),
        meta:{
            title:'鲁班学院交通引导',
            content:{
              keywords:'鲁班学院交通引导,鲁班学院官网交通引导,枣庄,枣庄鲁班学院交通引导,枣庄鲁班学院官网交通引导,鲁班职业学院交通引导,鲁班职业学院官网交通引导,',
              description:'鲁班学院交通引导'
            }
        },
    },
    {
        path: '/cooperation',
        name: '交流合作',
        component: () => import('@/views/huoxingViews/cooperation/home.vue'),
        meta:{
            title:'鲁班学院交流合作',
            content:{
              keywords:'鲁班学院交流合作,鲁班学院官网交流合作,枣庄,枣庄鲁班学院交流合作,枣庄鲁班学院官网交流合作,鲁班职业学院交流合作,鲁班职业学院官网交流合作,',
              description:'鲁班学院交流合作'
            }
        },
    },
    {
        path: '/news',
        name: '新闻',
        component: () => import('@/views/huoxingViews/news/home.vue'),
        meta:{
            title:'鲁班学院新闻',
            content:{
              keywords:'鲁班学院新闻,鲁班学院官网新闻,枣庄,枣庄鲁班学院新闻,枣庄鲁班学院官网新闻,鲁班职业学院新闻,鲁班职业学院官网新闻,',
              description:'鲁班学院新闻'
            }
        },
    },
    {
        path: '/OrganizationalStructure',
        name: '组织结构',
        component: () => import('@/views/huoxingViews/OrganizationalStructure/home.vue'),
        meta:{
            title:'鲁班学院组织结构',
            content:{
              keywords:'鲁班学院组织结构,鲁班学院官网组织结构,枣庄,枣庄鲁班学院组织结构,枣庄鲁班学院官网组织结构,鲁班职业学院组织结构,鲁班职业学院官网组织结构,',
              description:'鲁班学院组织结构'
            }
        },
    },
    {
        path: '/socialMedia/:navigatorID',
        name: '/socialMedia',
        component: () => import('@/views/huoxingViews/socialMedia/socialMedia.vue'),
        meta:{
            title:'社交媒体',
            content:{
              keywords:'鲁班学院社交媒体,鲁班学院官网社交媒体,枣庄,枣庄鲁班学院社交媒体,枣庄鲁班学院官网社交媒体,鲁班职业学院社交媒体,鲁班职业学院官网社交媒体,',
              description:'鲁班学院社交媒体'
            }
        },
    },
    {
        path: '/socialMediaList',
        name: '视频总览',
        component: () => import('@/views/huoxingViews/socialMedia/socialMediaList.vue'),
        meta:{
            title:'视频总览',
            content:{
              keywords:'鲁班学院视频总览,鲁班学院官网视频总览,枣庄,枣庄鲁班学院视频总览,枣庄鲁班学院官网视频总览,鲁班职业学院视频总览,鲁班职业学院官网视频总览,',
              description:'鲁班学院视频总览'
            }
        },
    },
    {
        path: '/education',
        name: '教育教学',
        component: () => import('@/views/huoxingViews/education/education.vue'),
        meta:{
            title:'教育教学',
            content:{
              keywords:'鲁班学院教育教学,鲁班学院官网教育教学,枣庄,枣庄鲁班学院教育教学,枣庄鲁班学院官网教育教学,鲁班职业学院教育教学,鲁班职业学院官网教育教学,',
              description:'鲁班学院教育教学'
            }
        },
    },
    {
        path: '/academicResearch',
        name: '学术研究',
        component: () => import('@/views/huoxingViews/academicResearch/academicResearch.vue'),
        meta:{
            title:'学术研究',
            content:{
              keywords:'鲁班学院学术研究,鲁班学院官网学术研究,枣庄,枣庄鲁班学院学术研究,枣庄鲁班学院官网学术研究,鲁班职业学院学术研究,鲁班职业学院官网学术研究,',
              description:'鲁班学院学术研究'
            }
        },
    },
    {
        path: '/campusServices',
        name: '校园服务',
        component: () => import('@/views/huoxingViews/campusServices/index.vue'),
        meta:{
            title:'校园服务',
            content:{
              keywords:'鲁班学院校园服务,鲁班学院官网校园服务,枣庄,枣庄鲁班学院校园服务,枣庄鲁班学院官网校园服务,鲁班职业学院校园服务,鲁班职业学院官网校园服务,',
              description:'鲁班学院校园服务'
            }
        },
    },
    {
        path: '/recruitmentAndEmployment',
        name: '招生就业',
        component: () => import('@/views/huoxingViews/recruitmentAndEmployment/index.vue'),
        meta:{
            title:'招生就业',
            content:{
              keywords:'鲁班学院招生就业,鲁班学院官网招生就业,枣庄,枣庄鲁班学院招生就业,枣庄鲁班学院官网招生就业,鲁班职业学院招生就业,鲁班职业学院官网招生就业,',
              description:'鲁班学院招生就业'
            }
        },
    },
    {
        path: '/library',
        name: '/library',
        component: () => import('@/views/huoxingViews/library/index.vue'),
        meta:{
            title:'图书馆',
            content:{
              keywords:'鲁班学院图书馆,鲁班学院官网图书馆,枣庄,枣庄鲁班学院图书馆,枣庄鲁班学院官网图书馆,鲁班职业学院图书馆,鲁班职业学院官网图书馆,',
              description:'鲁班学院图书馆'
            }
        },
    },
    {
        path: '/search',
        name: '搜索',
        component: () => import('@/views/huoxingViews/search/index.vue'),
        meta:{
            title:'搜索',
            content:{
              keywords:'鲁班学院搜索,鲁班学院官网搜索,枣庄,枣庄鲁班学院搜索,枣庄鲁班学院官网搜索,鲁班职业学院搜索,鲁班职业学院官网搜索,',
              description:'鲁班学院搜索'
            }
        },
    },
    {
        path: '/redIdeology/:navigatorID',
        name: '/redIdeology',
        component: () => import('@/views/huoxingViews/redIdeology/home.vue'),
        meta:{
            title:'红色思想',
            content:{
              keywords:'鲁班学院红色思想,鲁班学院官网红色思想,枣庄,枣庄鲁班学院红色思想,枣庄鲁班学院官网红色思想,鲁班职业学院红色思想,鲁班职业学院官网红色思想,',
              description:'鲁班学院红色思想'
            }
        },
    },
    {
        path: '/redIdeologyHome',
        name: '/redIdeologyHome',
        component: () => import('@/views/huoxingViews/redIdeology/redIdeologyHome.vue'),
        meta:{
            title:'红色思想专栏',
            content:{
              keywords:'鲁班学院红色思想专栏,鲁班学院官网红色思想专栏,枣庄,枣庄鲁班学院红色思想专栏,枣庄鲁班学院官网红色思想专栏,鲁班职业学院红色思想专栏,鲁班职业学院官网红色思想专栏,',
              description:'鲁班学院红色思想专栏'
            }
        },
    },
    {
        path: '/lubanCraftsmanCity/:navigatorID',
        name: '/lubanCraftsmanCity',
        component: () => import('@/views/huoxingViews/lubanCraftsmanCity/home.vue'),
        meta:{
            title:'鲁班工匠城',
            content:{
              keywords:'鲁班学院鲁班工匠城,鲁班学院官网鲁班工匠城,枣庄,枣庄鲁班学院鲁班工匠城,枣庄鲁班学院官网鲁班工匠城,鲁班职业学院鲁班工匠城,鲁班职业学院官网鲁班工匠城,',
              description:'鲁班学院鲁班工匠城'
            }
        },
    },
    {
        path: '/newBook/:navigatorID',
        name: '/newBook',
        component: () => import('@/views/huoxingViews/library/newBook.vue'),
        meta:{
            title:'鲁班图书馆新书推荐',
            content:{
              keywords:'鲁班学院鲁班图书馆新书推荐,鲁班学院官网鲁班图书馆新书推荐,枣庄,枣庄鲁班学院鲁班图书馆新书推荐,枣庄鲁班学院官网鲁班图书馆新书推荐,鲁班职业学院鲁班图书馆新书推荐,鲁班职业学院官网鲁班图书馆新书推荐,',
              description:'鲁班学院鲁班图书馆新书推荐'
            }
        },
    },
    {
        path: '/bookDetails/:articleID',
        name: '/bookDetails',
        component: () => import('@/views/huoxingViews/library/bookDetails.vue'),
        meta:{
            title:'鲁班图书馆书籍详情',
            content:{
              keywords:'鲁班学院鲁班图书馆书籍详情,鲁班学院官网鲁班图书馆书籍详情,枣庄,枣庄鲁班学院鲁班图书馆书籍详情,枣庄鲁班学院官网鲁班图书馆书籍详情,鲁班职业学院鲁班图书馆书籍详情,鲁班职业学院官网鲁班图书馆书籍详情,',
              description:'鲁班学院鲁班图书馆书籍详情'
            }
        },
    },
    {
        path: '/lubanArticle/:articleID/:navigatorID/:bodyComponent',
        name: '/lubanArticle',
        component: () => import('@/views/huoxingViews/lubanCraftsmanCity/luban.vue'),
        meta:{
            title:'鲁班工匠城·新闻网',
            content:{
              keywords:'鲁班学院鲁班鲁班工匠城·新闻网,鲁班学院官网鲁班鲁班工匠城·新闻网,枣庄,枣庄鲁班学院鲁班鲁班工匠城·新闻网,枣庄鲁班学院官网鲁班鲁班工匠城·新闻网,鲁班职业学院鲁班鲁班工匠城·新闻网,鲁班职业学院官网鲁班鲁班工匠城·新闻网,',
              description:'鲁班学院鲁班鲁班工匠城·新闻网'
            }
        },
    },
    
]
export default routers