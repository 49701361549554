import { createRouter,createWebHashHistory} from 'vue-router'
// import Home from '../views/huoxingViews/homePage/homePage.vue'
import Home from '../views/huoxingViews/homePage/homePageRest.vue'
import login from '../components/login/index'
import routers from './huoxiangViews/index.js'
import lunna from './huoxiangViews/index.js'
import { ref } from 'vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{
      title:'鲁班学院',
      content:{
        keywords:'鲁班学院,鲁班学院官网,枣庄,枣庄鲁班学院,枣庄鲁班学院官网,鲁班职业学院,鲁班职业学院官网,',
        description:'鲁班学院'
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta:{
      title:'鲁班学院',
      content:{
        keywords:'鲁班学院,鲁班学院官网,枣庄,枣庄鲁班学院,枣庄鲁班学院官网,鲁班职业学院,鲁班职业学院官网,',
        description:'鲁班学院'
      }
    },
  },
  {
    path: '/loading',
    name: '正在开发',
    component: () => import('../views/loading.vue'),
    meta:{
      title:'正在开发',
      content:{
        keywords:'正在开发,正在开发官网,枣庄,枣庄正在开发,枣庄正在开发官网,鲁班职业学院,鲁班职业学院官网,',
        description:'正在开发'
      }
    },
    
  },
  {
    path: '/404',
    name: '/404',
    component: () => import('@/views/404.vue')
  },
  ...routers,
  // ...lunna,
]
const  scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y:0 };
  // return { left: 0, top:0 };
}

const router = createRouter({
    //createWebHashHistory
    // createWebHistory
  history: createWebHashHistory('/'),
  routes,
  scrollBehavior,
})
const flag=ref()
const findItem=(row)=>{
  lunna.forEach((it)=>{
    if(it.path==row.path){
      flag.value= true
    }else{
      flag.value=  false
    }
  })
}
router.beforeEach((to, from, next) => {


  if (to.meta.content) {
    const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
        existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
        existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement('meta');
    descriptionMeta.setAttribute('name', 'description');
    descriptionMeta.setAttribute('content', to.meta.content.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]');
    if (contentMeta) {
        contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
    }
  }
  

  next();
	// if (!localStorage.getItem('sessionID')) {
  //   console.log(to);
  //   findItem(to)
	// 	if (to.name == "home"|| flag.value) {
	// 		next();
	// 	} else {
  //     login(to.fullPath)
	// 	}
	// } else {
	// 	next();
	// }
});
export default router
