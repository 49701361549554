<template>
  <header>
    <div class="main"> 
        ppppppppppppppppppppppppppp
    </div>
  </header>
</template>

<script>
import api from "@/common/api.js";
import {mapState,mapActions} from 'vuex'
import mapIMG from '@/assets/img/main/地图.png'
export default {
    data () {
        return {
            mapIMG,
        }
    },
    computed:{
        ...mapState({
            topHeaderListLeft:state =>state.nav.topHeaderListLeft,
            topHeaderListRight:state =>state.nav.topHeaderListRight
        })
    },
    created(){
    },
    methods:{
        // size每组数组多少个，如：8
        // array需要拆分的数组
        arrayChunk(array, size){
            let data = []
            for (let i = 0; i < array.length; i += size) {
                data.push(array.slice(i, i + size))
            }
            return data
        },
        handlerClick(row){
            let flag=!this.isShow
            this.$emit('openNavPage',flag)
        }

    }
}
</script>

<style scoped lang='scss'>
.main{
    width: 100%;
}
</style>