<template>
  <div class="mian custom-loading-svg" v-if="isShow" 
  >
    <div class="top" :class="{'botTop':type1IsShow}">
        <div class="left" @click='handleClickJumpArticle(obj0)'>
            <div class="imgBox">
                <img :src="obj0.listImage?obj0.listImage:new1IMG" alt="">
                <!-- <img :src="new1IMG" alt=""> -->
            </div>
            <div class="titleBox">
                
            </div>
            <div class="textspanBox">
                <div class="nameBoxText">
                    <span style="fontSize:1.25rem;opacity: 1;">{{obj0.name}}</span>
                    <span style="fontSize:1.25rem;margin:0 5px;opacity: 1;">|</span>
                    <span style="fontSize:1rem;opacity: 1;">{{obj0.shortName}}</span>
                </div>
                <div class="shortNameBoxText">
                    <p>{{obj0.description}}</p>
                </div>
            </div>
        </div>
        <div class="center" @click='handleClickJumpArticle(obj1)'>
            <div class="imgBox">
                <img :src="obj1.listImage?obj1.listImage:new2IMG" alt="">
                <!-- <img :src="new2IMG" alt=""> -->
            </div>
            <div class="titleBox" style="fontSize:1.25rem">
            </div>
            <div class="textspanBox">
                <div class="nameBoxText">
                    <span style="fontSize:1.25rem;opacity: 1;line-height: 2.5rem;">{{obj1.name}}</span>
                    <span style="fontSize:1.25rem;margin:0 .3125rem;opacity: 1;line-height: 2.5rem;">|</span>
                    <span style="fontSize:1rem;opacity: 1;line-height: 2.5rem;">{{obj1.shortName}}</span>
                </div>
                <div class="shortNameBoxText">
                    <p>{{obj1.description}}</p>
                </div>
            </div>
        </div>
        <div class="right">
            <ul style="width:100%;height:100%;display: flex;
                flex-direction: column;
                justify-content: space-between;">
                <li v-for="(it,index) in dataList" :key="index" @click='handleClickJumpArticle(it)'>
                    <div class="liBox">
                        <div class="drop">
                            <div class="it"></div>
                        </div>
                        <div class="text">
                            <div class="title">
                                {{it.articleName}}
                            </div>
                            <div class="content">
                                {{it.description}}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- <div class="item">

            </div> -->
        </div>
    </div>
    <div class="bot" :class="{'botTop2':type1IsShow2}">
        <div class="item" v-for="(item,index) in dataListIsGood" :key="index" @click='handleClickJumpArticle(item)'>
            <div class="big">
                <div class="bigBBox">
                    <p class="title">
                        {{item.articleName}}
                    </p>
                    <span class="description">
                        {{item.description}}
                    </span>
                </div>
                <div class="small">
                    <span>{{item.publishTimeStr.split(' ')[0].split('-')[1]}}.{{item.publishTimeStr.split(' ')[0].split('-')[2]}}</span>
                    <div class="link"></div>
                    <span>{{item.publishTimeStr.split(' ')[0].split('-')[0]}}</span>
                </div>
            </div>
            
        </div>
    </div>
    <div class="link" style="margin-top:26px;">
        <div class="nav">
            <div class="item" v-for="(it,index) in navList" :key="index" @click="handleClickJumpNav(it)">
                {{it.name}}
            </div>
            <div class="item" @click="handleClickJump">
                <el-icon  style="margin-top:.3125rem;fontSize:1rem"><DArrowRight /></el-icon>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import new1IMG from '@/assets/img/main/new1.png'
import new2IMG from '@/assets/img/main/new2.png'
import Common from "@/common/common";
import { DArrowRight} from "@element-plus/icons-vue";
import {
  getArticlePublishList,
  getNavigatorList,
} from "@/common/api.js";
import { mapState } from 'vuex';
export default {
    props: {
        id:{
            type:String,
            required:true,
        },
        isShow:{
            type:Boolean,
            default:true,
        }
    },
    components:{
        DArrowRight,
    },
    data () {
        return {
            new1IMG,
            new2IMG,
            dataList:[],//置顶
            obj0:{},
            obj1:{},
            dataListIsGood:[],//精选
            navList:[],
            loading:true,
            svg:`
            <path class="path" d="
                M 30 15
                L 28 17
                M 25.61 25.61
                A 15 15, 0, 0, 1, 15 30
                A 15 15, 0, 1, 1, 27.99 7.5
                L 15 15
            " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
            `,

        }
    },
    
    watch:{
        id:{
            handler(val){
                this.getArticlePublishListSSR(val)
                this.getArticlePublishListSSR2(val)
            }
        }
    },
    created(){
        this.getArticlePublishListSSR(this.id)
        this.getArticlePublishListSSR2(this.id)
        this.getNavigatorList()
        // let str='2023-09-14'
        // console.log(str.split('-'));
    },
    mounted(){
    },
    computed:{
        ...mapState({
            // loading:state=>state.user.loading,
            type1IsShow:state=>state.user.type1IsShow,
            type1IsShow2:state=>state.user.type1IsShow2
        }),
    },
    methods:{
        // 获取推荐文章列表--置顶
        getArticlePublishListSSR(val){
            this.loading=true
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeOrderSeq:2,
                isValid:1,
                isTop:1,
            },res=>{
                // this.dataList=res.data.body.data.rows
                res.data.body.data.rows.forEach(it => {
                    if(it.orderSeq==1){
                        this.obj0=it
                    } else if(it.orderSeq==2){
                        this.obj1=it
                    } else{
                        this.dataList.push(it)
                    }
                });
                this.loading=false
                this.$emit('typeCallBack',this.loading)
            })
        },
        // 获取推荐文章列表--精选
        getArticlePublishListSSR2(val){
            // this.loading=true
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeOrderSeq:2,
                isValid:1,
                isGood:1,
            },res=>{
                this.dataListIsGood=res.data.body.data.rows
                // this.loading=false&&this.loading
            })
        },
        // 获取导航
        getNavigatorList(){
            // this.loading=true
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: this.id,
                sortTypeOrder: 1,
                isValid:1,
                isLock:0,
            }, res => {
                this.navList=res.data.body.data.rows
                // this.loading=false&&this.loading
            })
        },
        // 导航跳转
        handleClickJumpNav(row){
            this.$router.push(
                {
                    path:row.functionURL,
                    query:{
                        navigatorID:row.navigatorID
                    }
                }
            )
        },
        // 文章跳转
        handleClickJumpArticle(row){
            console.log(row);
            if(row.functionURL && row.functionURL!="undefined"){
                window.open(row.functionURL, '_blank');
            }else{
                this.$router.push(
                    {
                    path:'/articleDetailsNews',
                    query:{
                        articleID:row.articleID,
                        navigatorID: this.id,
                    }
                    }
                )
            }
            // this.$router.push(
            //     {
            //     path:'/articleDetailsNews',
            //     query:{
            //         articleID:row.articleID,
            //         navigatorID: this.id,
            //     }
            //     }
            // )
        },
        // 跳转到新闻列表页
        handleClickJump(row){
            this.$router.push(
                {
                path:'/navListNews',
                query:{
                    navigatorID:'190e6cbb80f245e79832cf8b6750c634',
                    
                }
                }
            )
        },
        
    }
}
</script>

<style scoped lang='scss'>
.mian{
    margin-top: 1.875rem;
    width: 100%;
    background-color: transparent;
    .top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(20%);
        .left{
            width: 45%;
            height: 22.375rem;
            position: relative;
            overflow: hidden;
            filter: drop-shadow(0px 3px 3px #999);
            .imgBox{
                width: 100%;
                height: 22.375rem;
                overflow: hidden;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                // img:hover{
                //     transform: scale(1.2);
                // }
            }
            .titleBox{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3.125rem;
                line-height: 3.125rem;
                background-color: #333333;
                // background-image: linear-gradient(to bottom, #333333,#666);
                opacity: 0.7;
                font-size: 1.875rem;
                color:#fff;
                padding-left: .625rem;
                letter-spacing: .0625rem;
                z-index: 20;
                
            }
            .textspanBox{
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 21;
                width: 100%;
                height: 3.125rem;
                // line-height: 50px;
                font-size: 1.875rem;
                color:#fff;
                padding-left: .625rem;
                letter-spacing: .0625rem;
                .shortNameBoxText{
                    margin-top: .625rem;
                    width: 100%;
                    display: none;
                    p{
                        width: 80%;
                        font-size: .875rem;
                        color: #e1e1e1;
                        -webkit-line-clamp: 2;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                        display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                        -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                        overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                        text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                    }
                    
                }
            }
            
        }
        .left:hover{
            .imgBox{
                transform: scale(1.1);
                transition: ease all 0.5s;
            }
            .titleBox{
                height: 6.25rem;
                transition: ease all 0.5s;
            }
            .textspanBox{
                height: 6.25rem;
                transition: ease all 0.5s;
                .nameBoxText{
                    height: 1.875rem;
                    line-height: 1.875rem;
                    transition: ease all 0.5s;
                }
                .shortNameBoxText{
                    display: block;
                    transition: ease all 0.5s;
                    // height: 50px;
                }
            }
        }
        .center{
            width: 23%;
            height: 22.375rem;
            position: relative;
            overflow: center;
            overflow: hidden;
            filter: drop-shadow(0px .1875rem .1875rem #999);
            .imgBox{
                width: 100%;
                height: 22.375rem;
                overflow: hidden;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                // img:hover{
                //     transform: scale(1.2);
                // }
            }
            .titleBox{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3.125rem;
                line-height: 3.125rem;
                background-color: #333333;
                opacity: 0.7;
                font-size: 1.5rem;
                color:#fff;
                padding-left: .625rem;
                letter-spacing: .0625rem;
            }
            .textspanBox{
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 21;
                width: 100%;
                height: 3.125rem;
                // line-height: 50px;
                font-size: 1.875rem;
                color:#fff;
                padding-left: .625rem;
                letter-spacing: .0625rem;
                // .nameBoxText{
                //     width: 100%;
                    
                // }
                .shortNameBoxText{
                    margin-top: .625rem;
                    height: 2.5rem;
                    display: none;
                    p{
                        width: 80%;
                        font-size: .875rem;
                        color: #e1e1e1;
                        -webkit-line-clamp: 2;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                        display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                        -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                        overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                        text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                    }
                    
                }
            }
        }
        .center:hover{
            .imgBox{
                transform: scale(1.1);
                transition: ease all 0.5s;
            }
            .titleBox{
                height: 6.25rem;
                transition: ease all 0.5s;
            }
            .textspanBox{
                height: 6.25rem;
                transition: ease all 0.5s;
                .nameBoxText{
                    height: 1.875rem;
                    line-height: 1.875rem;
                    transition: ease all 0.5s;
                }
                .shortNameBoxText{
                    display: block;
                    transition: ease all 0.5s;
                    // height: 50px;
                }
            }
        }
        .right{
            width: 28%;
            height: 22.375rem;
            .liBox{
                width: 100%;
                height: 3.75rem;
                border-bottom:.0625rem solid #E4E4E4;
                display: flex;
                cursor: pointer;
                // transition: border-width .5s;
                position: relative;
                .drop{
                    width: 1.125rem;
                    .it{
                        margin-top: .625rem;
                        margin-right: .625rem;
                        width: .5rem;
                        height: .5rem;
                        background: #1f296a;
                    }
                }
                .text{
                    width: 100%;
                    .title{
                        font-size: 1.125rem;
                        font-weight: bold;
                        color: #333;
                        overflow: hidden;//（文字长度超出限定宽度，则隐藏超出的内容）
                        white-space: nowrap;//（设置文字在一行显示，不能换行）
                        text-overflow: ellipsis;//（规定当文本溢出时，显示省略符号来代表被修剪的文本）
                    }
                    .content{
                        font-size: 1rem;
                        width: 95%;
                        color: #666;
                        overflow: hidden;//（文字长度超出限定宽度，则隐藏超出的内容）
                        white-space: nowrap;//（设置文字在一行显示，不能换行）
                        text-overflow: ellipsis;//（规定当文本溢出时，显示省略符号来代表被修剪的文本）
                    }
                }
            }
            .liBox::before,
            .liBox::after{
                position: absolute;
                content: ' ';
                width: 0;
                height: 0;
                border-bottom: .0625rem solid transparent;
                box-sizing: border-box;
            }
            .liBox::before{
                // top: 0;
                // left: 0;
                bottom: 0;
                right: 0;
                /* 鼠标离开后的回退效果，若是不设置回退效果，则鼠标离开后就直接很生硬的隐藏了 */
                transition: border-bottom-color 0s ease-in 0.8s,width 0.2s ease-in 0.6s,height 0.2s ease-in 0.4s;
            }
            .liBox::after{            
                bottom: 0;
                // right: 0;
                // top: 0;
                left: 0;
                transition: border-bottom-color 0s ease-in 0.4s,width 0.2s ease-in 0.2s,height 0.2s ease-in;
            }
            .liBox:hover:before,
            .liBox:hover:after{
                width: 100%;
                height: 100%;
            }
            .liBox:hover::after{
                border-bottom-color: #1d5d93;
                border-left-color: transparent;
                transition: border-bottom-color 0s ease-out 0.4s,width 0.2s ease-out 0.4s,height 0.2s ease-out 0.6s;
            }
            .liBox:hover::before{            
                border-top-color: transparent;
                border-right-color: transparent;
                transition: width 0.2s ease-out,height 0.2s ease-out 0.2s;
            }
            .liBox:hover{
                // border-width: 10px;
                // animation: pulse 1s infinite;
            }
            /* 创建动画特效 */
            @keyframes pulse {
                0% {
                    // transform: scale(1);
                    border-bottom-color: #000;
                }
                50% {
                    // transform: scale(1.1);
                    border-bottom-color: #ff0000;
                }
                100% {
                    // transform: scale(1);
                    border-bottom-color: #000;
                }
            }
        }
    }
    .bot{
        width: 100%;
        // height: 316px;
        margin-top: 1.0625rem;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        transform: translateY(20%);
        opacity: 0;
        .item{
            width: 33%;
            height: 8.9375rem;
            box-sizing: border-box;
            border-radius: .3125rem .3125rem 0 .3125rem;
            margin-top: 1.8125rem;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            // align-items: center;
            cursor: pointer;
            
            .big{
                width: 90%;
                height: 100%;
                margin-left: 3.125rem;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                border: .0625rem solid #e9e9e9;
                position: relative;
                .bigBBox{
                    width: 100%;
                    height: 100%;
                    .title{
                        margin-top: 1.875rem;
                        margin-left: 3.125rem;
                        width: 80%;
                        font-size: 1.125rem;
                        font-weight: bold;
                        color: #333333;
                        overflow: hidden;//（文字长度超出限定宽度，则隐藏超出的内容）
                        white-space: nowrap;//（设置文字在一行显示，不能换行）
                        text-overflow: ellipsis;//（规定当文本溢出时，显示省略符号来代表被修剪的文本）
                    }
                    .description{
                        font-size: 1rem;
                        margin-left: 3.125rem;
                        width: 80%;
                        color: #666;
                        -webkit-line-clamp: 3;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                        display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                        -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                        overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                        text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                    }
                }
                .small{
                    position: absolute;
                    top: 33%;
                    z-index: 55;
                    // left: -50%;
                    transform: translate(-50%);
                    width: 3.8125rem;
                    height: 3.8125rem;
                    border: .0625rem solid #999999;
                    border-radius: .3125rem .3125rem 0 .3125rem;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    font-size: .9375rem;
                    font-weight: 500;
                    .link{
                        width: 1.875rem;
                        height: .0625rem;
                        background: #1f296a;
                    }
                }
            }
            .big::before{
                content: '';
                position: absolute;
                width: 0%;
                height: .0625rem;
                top: 0;
                right: 0;
                background-color: #58b6e5;
                transition: all 500ms linear;
            }
            .big::after{            
                content: '';
                position: absolute;
                width: 0%;
                height: .0625rem;
                bottom: 0;
                left: 0;
                background-color: #58b6e5;
                transition: all 500ms linear;
            }
            .bigBBox::before{            
                content: '';
                position: absolute;
                width: .0625rem;
                height: 0%;
                bottom: 0;
                left: 0;
                background-color: #58b6e5;
                transition: all 500ms linear;
            }
            .bigBBox::after{            
                content: '';
                position: absolute;
                width: .0625rem;
                height: 0%;
                top: 0;
                right: 0;
                background-color: #58b6e5;
                transition: all 500ms linear;
            }
            
        }
        .item:hover{
            .big{
                background-color: #f9f9f9;
            }
            .big::before,
            .big::after{
                width: 100%;
            }
            .bigBBox::after,.bigBBox::before{
                height: 100%;
            }
        }
        
        
        
    }
    .link{
        width: 100%;
        height: 2.75rem;
        // margin-top: 50px;
        // position: relative;
        .nav{
            // position: absolute;
            float: right;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding-top: 50px;
            .item{
                padding:0 .625rem;
                border-right: .0625rem solid #848484;
                font-size: .875rem;
                cursor: pointer;
            }
            .item:hover{
                color: #2878ff;
            }
        }
        .nav :nth-child(2){
            border-right:none;
        }
        .nav :last-child{
            border-right:none;
        }
    }
    .botTop{
        transform:translateY(0%);
        transition: all 400ms linear;
        animation: floatShow 400ms linear;
    }
    .botTop2{
        opacity: 1;
        transform:translateY(0%);
        transition: all 400ms 300ms linear;
        animation: floatShow 400ms 300ms linear;
    }
}
.example-showcase .el-loading-mask {
  z-index: 9;
}
@keyframes floatShow
{
    0%   {opacity: 0;}
    25%  {opacity: 0.25;}
    50%  {opacity: 0.5;}
    75%  {opacity: 0.6;}
    90%  {opacity: 0.8;}
    100% {opacity: 1;}
}
// @media only screen and (max-width: 1300px) {
//     .mian{
//         .top{
//             .left{
//                 width: 100%;
//             }
//             .center{
//                 display: none;
//             }
//             .right{
//                 display: none;
//             }
//         }
//         .bot{
//             .item{
//                 width: 100%;
//             }
//         }
//     }
// }
</style>