<template>
  <div id="app" class="app" ref="boxRef" @scroll="checkScroll">

    <topHeaderRestVue :isActiveSSE='isActiveSSE' :isShow='isShow' @openNavPage='openNavPage' :otherShow='otherShow'></topHeaderRestVue>

    <main ref="main" v-if="!isShow">
      <el-config-provider :locale="zhCn">
        <router-view :key="router.fullPath"/>
      </el-config-provider>
    </main>
    
    <Footer v-if="!isShow" :otherShow='otherShow' :linkSSEShow='linkSSEShow' />

    <el-drawer v-model="visible" :show-close="false" size="75%" :with-header="false">
      <div class="box">
        <div class="closeIcon">
          <div class="icon" @click="handleClose">
            <el-icon  style="fontSize:2.25rem" color="#fff"><Close /></el-icon>
          </div>
        </div>
        <div class="boxSSR">
          <div class="logoBox">
            <el-tooltip
              class="box-item"
              effect="light"
              content="点击返回首页"
              placement="top"
            >
              <img :src="logoText" alt="" @click="jumpHome">
            </el-tooltip>
            
          </div>
          <div class="navBox">
              <div class="navItem" v-for="(item, index) in dataSSR.navAllList" :key="index">
                <div class="navNameBox">
                  <span style="cursor: pointer;" @click="handleClickNavJump(item)">{{item.name}}</span>
                  <div class="articleBox">
                    <div class="articleItem" v-for="(it, index) in item.rows" :key="index">
                      <span @click="handleJumpNavListPage(it)">{{it.name}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="navItem" v-for="(item, index) in dataSSR.newsNavList" :key="index">
                <div class="navNameBox">
                  <span style="cursor: pointer;" @click="handleClickNavJump(item)">{{item.name}}</span>
                  <div class="articleBox">
                    <div class="articleItem" v-for="(it, index) in item.rows" :key="index">
                      <span @click="handleJumpNavListPage(it)">{{it.name}}</span>
                      <el-icon v-if="it.navigatorID=='7ceb7f6c74284133ac8913f07b2f0856' && it.navigatorID!=smallNavListBox" style="margin-left:.3125rem;fontSize:1rem;color:#666;cursor: pointer;" @click="openSmallNavListBox(it)"><ArrowDown /></el-icon>
                      <el-icon v-if="it.navigatorID=='7ceb7f6c74284133ac8913f07b2f0856' && it.navigatorID==smallNavListBox" style="margin-left:.3125rem;fontSize:1rem;color:#666;cursor: pointer;" @click="openSmallNavListBox(it)"><ArrowUp /></el-icon>
                      <div class="smallNavListBox" v-if="it.navigatorID==smallNavListBox">
                        <p v-for="(row) in dataSSR.smallNavList" :key="row.navigatorID" @click.stop="jumpNewsListBox(row)">{{row.name}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      
    </el-drawer>
  </div>
</template>

<script setup>
import { inject,ref,onMounted, reactive,watch} from "vue";
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';
import Header from "./components/header/header.vue"
import topHeader from "./components/header/topHeader.vue"
import Footer from "./components/footer/footer.vue"
import shortcutVue from "@/components/shortcut/shortcut.vue";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import topHeaderCopyVue from "./components/header/topHeaderCopy.vue";
import topHeaderRestVue from "./components/header/topHeaderRest.vue";
import { Close,ArrowDown,ArrowUp} from "@element-plus/icons-vue";
import logoText from '@/img/logo2.png'
import {
  getNavigatorList,
  getArticlePublishList,
  getNavigatorSimpleArticleList,
} from "@/common/api.js";
import Jump from '@/components/js/index.js'
const visible = ref(false)

let Common = inject('Common')
let main = ref()
let headerVue=ref()
let linkIsShow=ref(false)
let linkSSEShow=ref(true)
onMounted(()=>{
  main.value.style.minHeight = `${document.documentElement.clientHeight-473-80}px`
  checkScroll()

  if (_isMobile()) {
    console.log('手机端')
    // this.$router.replace('/mb_index')
    window.location.href="http://mobile.sdlb.cc/"; 
  } else {
    console.log('pc端')
    // this.$router.replace('/pc_index')
  }
})
   
// 判断是否是手机端，如果是，返回true
const _isMobile=()=>{
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}
const store = useStore();
const router = useRoute();
store.dispatch('user/getUserInfo');
store.dispatch('nav/getNavList');
store.dispatch('nav/getSwiper');
store.dispatch('nav/getNewSwiper');
store.dispatch('nav/getNavList3')

const boxRef = ref(null);
const isShow=ref(false)
const isShowShortNav=ref(false)
const isActiveSSE=ref(false)
// 检测滚动
const checkScroll=()=>{
  let ss=boxRef.value.scrollTop * (944/document.documentElement.scrollHeight)
  // console.log('网站',ss);
  store.commit('user/setAppRoll',boxRef.value.scrollTop);
  if(ss>0){
    isActiveSSE.value=true
    store.commit('user/setChangeHeaderColor',true);
  }else if(ss>700){
    isShow.value=true
  }else{
    isShow.value=false
    isActiveSSE.value=false
    store.commit('user/setChangeHeaderColor',false);
  }
  if(ss>=200){
    store.commit('user/setType1IsShow',true);
  }else{
    store.commit('user/setType1IsShow',false);
  }
  if(ss>=690){
    store.commit('user/setType1IsShow2',true);
  }else{
    store.commit('user/setType1IsShow2',false);
  }
  if(ss>700){
    isShowShortNav.value=true
  }else{
    isShowShortNav.value=false
  }
  if(ss==883){
    store.commit('user/setIsRoll',0);
  }
  if(ss>=310){
    store.commit('user/setType2IsShow',true);
    
  }else{
    store.commit('user/setType2IsShow',false);
  }
  if(ss>=1220){
    store.commit('user/setType3IsShow',true);
  }else{
    store.commit('user/setType3IsShow',false);
  }
  if(ss>=2184){
    store.commit('user/setType4IsShow1',true);
  }else{
    store.commit('user/setType4IsShow1',false);
  }
  if(ss>=2240){
    store.commit('user/setType4IsShow2',true);
  }else{
    store.commit('user/setType4IsShow2',false);
  }
}
// 返回顶部
const backTopFn=()=>{
  let top = document.querySelector('.app').scrollTop
  const timeTop = setInterval(() => {
        document.querySelector('.app').scrollTop = top -= 50//一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 20)//定时调用函数使其更顺滑
}
// 打开一个导航页面
const openNavPage=(flag)=>{
  // console.log(flag);
  visible.value=flag
}
// 
// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const debounce = (fn, delay) => {

let timer = null;

return function () {

  let context = this;

  let args = arguments;

  clearTimeout(timer);

  timer = setTimeout(function () {

    fn.apply(context, args);

  }, delay);

}

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

constructor(callback) {

  callback = debounce(callback, 16);

  super(callback);

}

}
const smallNavListBox=ref()

const dataSSR=reactive({
  navAllList:[],
  newsNavList:[],
  smallNavList:[],
})
const getNewsNavList=()=>{
  getNavigatorList({
    siteID: Common.siteID,
    navigatorID: '15d858fedddd427181bd260256174fbb',
    pageNumber:1,
    sortTypeOrder: 1,
  },res=>{
    if(res.data.header.code==0){
      dataSSR.newsNavList=res.data.body.data.rows
      dataSSR.newsNavList.forEach(it=>{
        getNavigatorList({
            siteID: Common.siteID,
            navigatorID: it.navigatorID,
            sortTypeOrder: 1,
            pageNumber:9999,
        }, response => {
          if(response.data.header.code==0){
            it.rows=response.data.body.data.rows
          }
        })
      })
    }

  })
}
getNewsNavList()
// 获取所有导航--用于侧边快捷导航
const getAllNavList=()=>{
  getNavigatorList({
    siteID: Common.siteID,
    navigatorID: '9f2d420f43c344cbb59a2f5914582e4a',
    sortTypeOrder: 1,
  },(res)=>{
    if (res.data.header.code === 0) {
      res.data.body.data.rows.forEach((it)=>{
        if(it.navigatorID=='02ad7adaa33949ddb0becd5329ef90c6'){
        }else{
          dataSSR.navAllList.push(it)
        }
      })
      dataSSR.navAllList.forEach(item => {
        getNavigatorList({
            siteID: Common.siteID,
            navigatorID: item.navigatorID,
            sortTypeOrder: 1,
            pageNumber:9999,
        }, response => {
          if(response.data.header.code === 0){
            let data=response.data.body.data.rows
            // 
            if(item.navigatorID=='a79515c42e25431a970051b874578904'){
              getNavigatorSimpleArticleList({
                    companyID:Common.companyID,
                    navigatorID:item.navigatorID,
                    pageNumber:99,
                    sortTypeOrder:1,
                    isValid:1,
                },res=>{
                    item.rows=[...res.data.body.data.rows,...data]
                })
            }else{
              item.rows=data
            }
          }
        })
      });
    }
  })
}
getAllNavList()
// 获取所有的视频导航
const getAllVideoList=()=>{
  getNavigatorList({
      siteID: Common.siteID,
      companyID:Common.companyID,
      sortTypeOrder: 1,
      pageNumber:9999,
  }, response => {
    if(response.data.header.code === 0){
      store.commit('user/setAllVideoList',response.data.body.data.rows);
      store.commit('user/setIsTopVideoList',response.data.body.data.rows);
    }
  })
}
getAllVideoList()
// 关闭
const handleClose = ()=>{
  visible.value=false
}
import routerSSR from "@/router/index.js";
import { ElMessage } from 'element-plus'
// 侧边菜单处理
const handleJumpNavListPage=(row)=>{
  // console.log(row);
  if(row.navigatorID=='d1a374b7821c4669b39fd5ec7862f22c'){//校园风光
    Jump.handleClickNavJump({
      path:row.functionURL,
      navigatorID:row.navigatorID,
    })
  }else if(row.navigatorID=='2744b18828644ead8db54e4988f09e84' || row.navigatorID=='2cf9c6b42fc344419ec4e7153bc3cc90'){//校园生活、校园活动
    routerSSR.push({
      path:row.functionURL,
      query:{
          navigatorID:row.parentID,
          childreNavID:row.navigatorID,
      }
    })
  }else if(row.articleID=='a5305aa6e0484366878ef0a7c28865a9' || row.articleID=='1baf6843260943c1b8126f3bfd1d01f3' || row.articleID=='0faa17a123204cc5bf0884278a715e61'){//学院·简介/校徽·校训/学院·章程
    routerSSR.push({
      path:'/articleList',
      query:{
          navigatorID:'a79515c42e25431a970051b874578904',
          articleID:row.articleID,
      }
    })
  }else if(row.navigatorID=='57d910ac820944828e76165a26d47d67'){//信息公开
    ElMessage({
      message: '当前网页正在建设中...',
      type: 'warning',
    })
  }else if(row.navigatorID=='3facf0451ea84cabb16c03b896a128f3'){//现任领导
    routerSSR.push({
      path:'/articleList',
      query:{
          navigatorID:'a79515c42e25431a970051b874578904',
          articleID:row.navigatorID,
      }
    })
  }else if(row.parentID=='0ca0a0135bdc4c60a23a9a4b3ecde0d5'){//组织结构
    let articleID=row.functionURL.split('=')[1]
    routerSSR.push({
      path:row.functionURL,
      query:{
          navigatorID:row.parentID,
          articleID:articleID,
      }
    })
  }else if(row.parentID=='190e6cbb80f245e79832cf8b6750c634' || row.parentID=='7ceb7f6c74284133ac8913f07b2f0856'){//对于新闻及其子导航处理
    let articleID=row.functionURL.split('=')[1]
    routerSSR.push({
      path:row.functionURL,
      query:{
          navigatorID:'190e6cbb80f245e79832cf8b6750c634',
      }
    })
  }else{

  }
  handleClose()
}
const handleClickNavJump=(row)=>{
  Jump.handleClickNavJump({
    path:row.functionURL,
    navigatorID:row.navigatorID
  })
  handleClose()
}
// 跳转之后，页面回到最顶部
// console.log('路由',router);
// window.scrollTo(0, 0)
let otherShow=ref(true)
 //监听器--这里是当跳到指定路由的时候，头部导航和底部导航显示不显示
 watch(router,(newVal,oldVal)=>{
  // console.log('newVal',newVal);
  if(newVal.fullPath){
    boxRef.value.scrollTop=0
  }
  
  if(newVal.name=="/redIdeology" || newVal.name=="/lubanCraftsmanCity" || newVal.name=="/lubanArticle" || newVal.name=="/newBook" || newVal.name=="/bookDetails" || newVal.name=="/404" || newVal.name=="/redIdeologyHome"){
    otherShow.value=false
  }else if(newVal.name=="/library"){
    console.log('newVal',newVal);
    otherShow.value=true
    store.dispatch('nav/getLibarayNavList');
  }else{
    otherShow.value=true
    store.dispatch('nav/getHeaderNavList')
  }
 })
  watch(store.state,(newVal,oldVal)=>{
  // console.log('newVal',newVal.user.isRoll);
  if(newVal.user.isRoll){
    boxRef.value.scrollTop=newVal.user.isRoll
  }
 })
 const jumpHome=()=>{
  Jump.handleClickNavJumpHome({
    path:'/',
  })
  handleClose()
  
  store.commit('user/setChangeHeaderColor',false);
  store.commit('publicSSR/setSpanColor','');
 }
 const openSmallNavListBox=(item)=>{
  if(smallNavListBox.value=='7ceb7f6c74284133ac8913f07b2f0856'){
    smallNavListBox.value=""
  }else{
      getNavigatorList({
        siteID: Common.siteID,
        navigatorID: item.navigatorID,
        sortTypeOrder: 1,
        pageNumber:9999,
    }, response => {
      if(response.data.header.code === 0){
        dataSSR.smallNavList=response.data.body.data.rows
        smallNavListBox.value="7ceb7f6c74284133ac8913f07b2f0856"
      }
    })
  }
 }
 const jumpNewsListBox=(row)=>{
  routerSSR.push({
      path:'/navListNews',
      query:{
          navigatorID:'190e6cbb80f245e79832cf8b6750c634',
          childNavID:row.navigatorID,
      }
    })
 }
</script>

<style lang="scss">
#app,
html,
body,
.app{
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
#app{
  // padding-top: 50px;
  font-family: '思源黑体 Normal', Helvetica, 'Microsoft YaHei', Arial, sans-serif !important;
  font-size: 100%;
  color: #333333;
  height: 100%;
  // 火狐浏览器滚动条自定义
  scrollbar-width: none;
  /* thin 很细 */
  /* auto 默认 */
  /* none 将滚动条隐藏 */
}
.app{
  main{
    width: 100%;
    // margin-top:75px;
  }
}

</style>
<style lang="scss" scoped>
::v-deep .el-drawer.rtl{
    height: 96% !important;
    margin-top: 1% !important;
    border-radius: 10px 0 0 10px !important;
    background-color: #1f296a;
    .el-drawer__body{
      padding: none !important;
    }
}
::v-deep .el-drawer{
  --el-drawer-padding-primary: var(--el-dialog-padding-primary, 0px);
}
.box{
  width: 100%;
  height: 100%;
  display: flex;
  .boxSSR{
    width: 93%;
    height: 100%;
    background-color: #fff;
    float: right;
    border-radius: 10px 0 0 10px;
    .logoBox{
      width: 100%;
      height: 6.25rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #c2c2c2;
      padding-left: 6.25rem;
      cursor: pointer;
      img{
        width:12.9375rem;
        // height: 100%;
      }
    }
    .navBox{
      width: 100%;
      padding-left: 6.25rem;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .navItem{
        width: 33%;
        padding-top: 3.75rem;
        min-height: 9.375rem;
        .navNameBox{
          font-size: 1.25rem;
          color: #1f296a;
          font-weight: 800;
          .articleBox{
            width: 80%;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding-top: .3125rem;
            .articleItem{
              width: 33%;
              margin-top: .3125rem;
              position: relative;
              span{
                font-size: 1rem;
                color: #666;
                font-weight: 400;
                cursor: pointer;
              }
              .smallNavListBox{
                position: absolute;
                left: 0;
                top: 2.5rem;
                p{
                  font-size: .875rem;
                  color: #1f296a;
                  cursor: pointer;
                  margin-bottom: .3125rem;
                }
              }
            }
          }
        }
      }
      .newsNavBox{
        width: 33%;
        padding-top: 3.75rem;
        min-height: 9.375rem;
      }
    }
  }
  .closeIcon{
    flex: 1;
    position: relative;
    .icon{
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
}
::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    border-radius: 3px;
  }

  /*定义滚动条轨道 内阴影+圆角*/

  ::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
  }

  /*定义滑块 内阴影+圆角*/

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background: rgba(0, 0, 0, 0.15);
  }

</style>
