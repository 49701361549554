<template>
  <div class="main">
    <div class="box">
        <div class="item" v-for="(it,index) in shortCutList" :key="index" @click.stop="backCallFn(it)">
            <div class="item_Img">
                <img :src="it.largeIcon" alt="">
            </div>
            <p>
                {{it.name}}
            </p>
        </div>
    </div> 

  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
    data(){
        return {

        }
    },
    computed:{
        ...mapState({
            shortCutList:state =>state.nav.shortCutList
        })
    },
    created(){},
    methods:{
        backCallFn(it){
            if(it.name=='回到顶部'){
                // console.log(window);
                // window.scroll({
                //     top: 0,
                //     behavior: 'smooth' // 可以将此处的'smooth'改为'autoscroll'以立即滚动
                // });
                this.$emit('backTop')
            }
        }
    },
}
</script>

<style scoped lang='scss'>
.main{
    width: 78px;
    position: fixed;
    top:255px;
    right: 35px;
    z-index: 99999;
    .box{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 2px 11px 0px rgba(76,80,134,0.25);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .item{
            width: 72px;
            height: 120px;
            cursor: pointer;
            border-bottom: 1px solid #E7E6E6;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            .item_Img{
                width: 26px;
                height: 26px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            p{
                width: 33px;
            }
        }
    }
}

</style>