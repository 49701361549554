import router from "@/router/index.js";
const handleClickNavJump=(row)=>{
  router.push(
    {
      path:row.path,
      query:{
          navigatorID:row.navigatorID
      }
    }
  )
}

const handleClickArticleJump=(row)=>{
    router.push(
      {
        path:row.path,
        query:{
            articleID:row.articleID
        }
      }
    )
}
const handleClickNavJumpHome=(row)=>{
  router.push({
    path:row.path,
  })
}
export default {
    handleClickNavJump,
    handleClickArticleJump,
    handleClickNavJumpHome,
} 