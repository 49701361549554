<template>
  <div class="mian custom-loading-svg" v-if="isShow" 
  >
    <div class="leftBox">
        <div class="leftBoxTop">
            <div class="leftBoxTopItem dl state2" :class="{'activeItem':isActiveItemID==it.articleID}" v-for="(it,index) in dataList" :key="index" 
            :style="{'paddingTop':isActiveItemID==it.articleID && index==0?'0':'1.5rem'}"
            @click="handleClickJumpArticle(it)"
            v-on:mouseenter="onMouseEnter(it)" v-on:mouseleave="onMouseLeave(it)"
            >
                <div class="time">
                    <em>{{new Date(it.startDateStr).getDate()>=10?new Date(it.startDateStr).getDate():`0${new Date(it.startDateStr).getDate()}`}}</em>
                    <span>{{new Date(it.startDateStr).getFullYear()}}.{{new Date(it.startDateStr).getMonth()+1>=10?new Date(it.startDateStr).getMonth()+1:`0${new Date(it.startDateStr).getMonth()+1}`}}</span>
                </div>

                <div class="dd">
                    <h3>{{it.name}}</h3>
                    <div class="ms">
                        {{it.description}}
                    </div>
                    <div class="row">
                        <el-icon  style="fontSize:1.5rem;color: #e8e1d4;"><ArrowRight /></el-icon>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="leftBoxBot">
            <div class="nav">
                <div class="item" style="border-right:.125rem solid #F6F0E8" v-for="(it,index) in navList" :key="index" @click="handleClickJumpNav(it)">
                    {{it.name}}
                </div>
                <div class="item" @click="handleClickJump">
                    <span>更多</span>
                    <el-icon  style="fontSize:1rem"><ArrowRight /></el-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="rightBox">
        <div class="rightBoxLeft">
            <img :src="goodObj1.listImage" alt="">
            <div class="shade">
                <a target="_blank" class="icon" @click="handleClickJumpArticle(goodObj1)">
                    <el-icon style="font-size: 1.5rem;color: #009566;"><Search /></el-icon>
                </a>
            </div>
        </div>
        <div class="rightBoxRight">
            <div class="rightBoxRightItem">
                <img :src="goodObj2.listImage" alt="">
                <div class="shade">
                    <a target="_blank" class="icon" @click="handleClickJumpArticle(goodObj2)">
                        <el-icon style="font-size: 1.5rem;color: #009566;"><Search /></el-icon>
                    </a>
                </div>
            </div>
            <div class="rightBoxRightItem2">
                <img :src="goodObj3.listImage" alt="">
                <div class="shade">
                    <a target="_blank" class="icon" @click="handleClickJumpArticle(goodObj3)">
                        <el-icon style="font-size: 1.5rem;color: #009566;"><Search /></el-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import new1IMG from '@/assets/img/main/new1.png'
import new2IMG from '@/assets/img/main/new2.png'
import Common from "@/common/common";
import { DArrowRight,ArrowRight,Search} from "@element-plus/icons-vue";
import {
  getArticlePublishList,
  getNavigatorList,
} from "@/common/api.js";
import { mapState } from 'vuex';
export default {
    props: {
        id:{
            type:String,
            required:true,
        },
        isShow:{
            type:Boolean,
            default:true,
        }
    },
    components:{
        DArrowRight,
        ArrowRight,
        Search,
    },
    data () {
        return {
            new1IMG,
            new2IMG,
            dataList:[],//置顶
            obj0:{},
            obj1:{},
            dataListIsGood:[],//精选
            navList:[],
            loading:true,
            goodObj1:{},
            goodObj2:{},
            goodObj3:{},
            isActiveItemID:'',
        }
    },
    
    watch:{
        id:{
            handler(val){
                this.getArticlePublishListSSR(val)
                this.getArticlePublishListSSR2(val)
            }
        }
    },
    created(){
        this.getArticlePublishListSSR(this.id)
        this.getArticlePublishListSSR2(this.id)
        this.getNavigatorList()
    },
    mounted(){
        
    },
    computed:{
        ...mapState({
            // loading:state=>state.user.loading,
            type1IsShow:state=>state.user.type1IsShow,
            type1IsShow2:state=>state.user.type1IsShow2
        }),
    },
    methods:{
        // 获取推荐文章列表--置顶
        getArticlePublishListSSR(val){
            this.loading=true
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:5,
                sortTypeStartdate:1,
                isValid:1,
                isTop:1,
            },res=>{
                this.dataList=res.data.body.data.rows
                this.isActiveItemID=res.data.body.data.rows[0].articleID
                // res.data.body.data.rows.forEach(it => {
                //     if(it.orderSeq==1){
                //         this.obj0=it
                //     } else if(it.orderSeq==2){
                //         this.obj1=it
                //     } else{
                //         this.dataList.push(it)
                //     }
                // });
                new Date().getDate()
                this.loading=false
                this.$emit('typeCallBack',this.loading)
            })
        },
        // 获取推荐文章列表--精选
        getArticlePublishListSSR2(val){
            // this.loading=true
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:3,
                sortTypeOrderSeq:2,
                isValid:1,
                isGood:1,
            },res=>{
                this.dataListIsGood=res.data.body.data.rows
                this.goodObj1=res.data.body.data.rows[0]
                this.goodObj2=res.data.body.data.rows[1]
                this.goodObj3=res.data.body.data.rows[2]
                // this.loading=false&&this.loading
            })
        },
        // 获取导航
        getNavigatorList(){
            // this.loading=true
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: this.id,
                sortTypeOrder: 1,
                isValid:1,
                isLock:0,
            }, res => {
                this.navList=res.data.body.data.rows
                // this.loading=false&&this.loading
            })
        },
        // 导航跳转
        handleClickJumpNav(row){
            this.$router.push(
                {
                    path:row.functionURL,
                    query:{
                        navigatorID:row.navigatorID
                    }
                }
            )
        },
        // 文章跳转
        handleClickJumpArticle(row){
            console.log(row);
            if(row.functionURL && row.functionURL!="undefined"){
                window.open(row.functionURL, '_blank');
            }else{
                this.$router.push(
                    {
                    path:'/articleDetailsNews',
                    query:{
                        articleID:row.articleID,
                        navigatorID: this.id,
                    }
                    }
                )
            }
            // this.$router.push(
            //     {
            //     path:'/articleDetailsNews',
            //     query:{
            //         articleID:row.articleID,
            //         navigatorID: this.id,
            //     }
            //     }
            // )
            
        },
        // 跳转到新闻列表页
        handleClickJump(row){
            this.$router.push(
                {
                path:'/navListNews',
                query:{
                    navigatorID:'190e6cbb80f245e79832cf8b6750c634',
                    
                }
                }
            )
        },
        // 
        onMouseEnter(row) {
        this.isActiveItemID=row.articleID
        },
        onMouseLeave(row) {
            console.log("Div is no longer hovered!");
        }
        
    }
}
</script>

<style scoped lang='scss'>
.mian{
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: space-between;
    padding-top: 2.75rem;
    .leftBox{
        width: 35.8125rem;
        height: 40.95rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .leftBoxTop{
            width: 33.3125rem;
            height: 34.75rem;
            .leftBoxTopItem{
            }
            .dl{
                width: 33.3125rem;
                height: 7.125rem;//223px
                border-bottom: .125rem solid #F6F0E8;
                cursor: pointer;
                overflow: hidden;
                padding: 1.5rem 0;
                height: 6.25rem;
                border-bottom: .1875rem solid #f9f4ed;
                position: relative;
                background-color: #fff;
                transition: all .25s cubic-bezier(.17,.67,.69,.85);
                z-index: 1;
                .time{
                    float: left;
                    width: 5.3125rem;
                    height: 2.5rem;
                    position: relative;
                    transition: all .4s;
                    em{
                        display: block;
                        font-family: Rajdhani-Regular;
                        margin-bottom: .25rem;
                        letter-spacing: -2px;
                        transition: all .4s;
                        width: 2.375rem;
                        height: 2.1875rem;
                        font-size: 3.125rem;
                        font-weight: 400;
                        color: #B4A48A;
                        line-height: 1.6875rem;
                    }
                    span{
                        display: block;
                        font-family: Rajdhani-Regular;
                        transition: all .4s;
                        width: 4.125rem;
                        height: 1rem;
                        font-size: 1.25rem;
                        font-weight: 700;
                        color: #E34645;
                        line-height: 1.0625rem;
                    }
                }
                .time::before{
                    content: "";
                    border-right: .125rem solid #d9d9d9;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    transition: all .4s;
                }
                .dd{
                    float: right;
                    width: calc(100% - 7.5rem);
                    padding-right: 2.8125rem;
                    position: relative;
                    h3{
                        line-height: 1.5rem;
                        margin-bottom: .4375rem;
                        margin-top: -0.125rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        width: 21.99rem;
                        font-size: 1.125rem;
                        font-weight: 400;
                        color: #666666;
                    }
                    .ms{
                        display: none;
                    }
                    .row{
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                }
                
            }
            .activeItem{
                width: 33.3125rem;
                height: 13rem;//223px
                .dd{
                    width: 25.1875rem;
                    height: 13rem;
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: #555555;
                    line-height: 2.125rem;
                    box-sizing: border-box;
                    h3{
                        width: 25.1875rem;
                        font-size: 1.5rem;
                        font-weight: 400;
                        color: #555555;
                        line-height: 2.125rem;
                    }
                    .ms{
                        display: block;
                        width: 25.1875rem;
                        height: 5rem;
                        font-size: 1rem;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.8125rem;
                        text-align: justify;
                        -webkit-line-clamp: 3;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                        display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                        -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                        overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                        text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                    }
                    .row{
                        display: none;
                    }
                }
                .time{
                    width: 6.8125rem;
                    height: 6.8125rem;
                    background: #EAEAE7;
                    border-radius: .625rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    em{
                        width: 100%;
                        height: 2.625rem;
                        text-align: center;
                        font-size: 3.75rem;
                        font-family: Rajdhani-Regular;
                        font-weight: 400;
                        color: #B4A48A;
                        line-height: 1.6875rem;
                    }
                    span{
                        width: 100%;
                        height: 1rem;
                        text-align: center;
                        font-size: 1.25rem;
                        font-family: Rajdhani-Regular;
                        font-weight: 700;
                        color: #E34645;
                        line-height: 1.0625rem;
                    }
                }
                .time::before{
                    content: "";
                    border-right: none;
                }
                
            }

        }
        .leftBoxBot{
            width: 33.3125rem;
            height: 5.875rem;
            position: relative;
            .nav{
                position: absolute;
                bottom: 0;
                right: 0;
                width: 15rem;
                height: 2.375rem;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                // border-bottom: .125rem solid #F6F0E8;
                .item{
                    padding: 0 .625rem;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #B4A48A;
                    text-align: center;
                    cursor: pointer;
                }
                .item:hover{
                    color: #e40000;
                }
            }
        }
    }
    .rightBox{
        width: 51.875rem;
        height: 40.625rem;
        display: flex;
        justify-content: space-between;
        .rightBoxLeft{
            width: 22.5rem;
            height: 40.3125rem;
            background-color: skyblue;
            position: relative;
            cursor: pointer;
            img{
                width: 22.5rem;
                height: 40.3125rem;
            }
            .shade{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
                opacity: 0;
                background: rgba(0,0,0,.7);
                transition: all .4s;
                .icon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 2.875rem;
                    transform: translateY(1.25rem);
                    transition: inherit;
                    height: 2.875rem;
                    border: .125rem solid #fff;
                    margin: -1.4375rem 0 0 -1.4375rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #fff no-repeat 50%;
                }
            }
        }
        .rightBoxLeft:hover{
            .shade{
                opacity: 1;
            }
        }
        .rightBoxRight{
            width: 28.75rem;
            height:40.3125rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .rightBoxRightItem{
                width: 28.75rem;
                height: 17.5rem;
                background-color: pink;
                cursor: pointer;
                position: relative;
                img{
                    width: 28.75rem;
                    height: 17.5rem;
                }
                .shade{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    opacity: 0;
                    background: rgba(0,0,0,.8);
                    transition: all .4s;
                    .icon{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 2.875rem;
                        transform: translateY(1.25rem);
                        transition: inherit;
                        height: 2.875rem;
                        border: .125rem solid #fff;
                        margin: -1.4375rem 0 0 -1.4375rem;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #fff no-repeat 50%;
                    }
                }
            }
            .rightBoxRightItem:hover{
                .shade{
                    opacity: 1;
                }   
            }
            .rightBoxRightItem2{
                width: 28.75rem;
                height: 22.1875rem;
                background-color: pink;
                cursor: pointer;
                position: relative;
                img{
                    width: 28.75rem;
                    height: 22.1875rem;
                }
                .shade{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    opacity: 0;
                    background: rgba(0,0,0,.8);
                    transition: all .4s;
                    .icon{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 2.875rem;
                        transform: translateY(1.25rem);
                        transition: inherit;
                        height: 2.875rem;
                        border: .125rem solid #fff;
                        margin: -1.4375rem 0 0 -1.4375rem;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #fff no-repeat 50%;
                    }
                }
            }
            .rightBoxRightItem2:hover{
                .shade{
                    opacity: 1;
                }   
            }
        }
    }
}

</style>
<style lang='scss' scoped>

</style>