import { getOneMemberDetail } from "../../common/api"
export default {
    namespaced: true,
    state() {
        return {
            userinfo: {},
            loading:true,
            type2IsShow:false,
            type3IsShow:false,
            type1IsShow:false,
            type1IsShow2:false,
            type4IsShow1:false,
            type4IsShow2:false,
            isRoll:0,
            appRoll:0,//整个项目的滚动条位置
            changeHeaderColor:false,//控制导航条样式的开关
            allVideoNavList:[],//所有视频导航
            isTopVideoList:[],//置顶的视频导航
            scroll:0,//整个页面的滚动距离
        }
    },
    mutations: {
        userinfo(state, data) {
            state.userinfo = { ...data }
        },
        setLoading(state,data){
            state.loading=data
        },
        setType2IsShow(state,data){
            state.type2IsShow=data
        },
        setType3IsShow(state,data){
            state.type3IsShow=data
        },
        setType1IsShow(state,data){
            state.type1IsShow=data
        },
        setType1IsShow2(state,data){
            state.type1IsShow2=data
        },
        setType4IsShow1(state,data){
            state.type4IsShow1=data
        },
        setType4IsShow2(state,data){
            state.type4IsShow2=data
        },
        setIsRoll(state,data){
            state.isRoll=data
        },
        setAppRoll(state,data){
            state.appRoll=data
        },
        setScroll(state,data){
            state.scroll=data
        },
        setChangeHeaderColor(state,data){
            state.changeHeaderColor=data
            // console.log('变化之值',state.changeHeaderColor?'白色':'透明');
        },
        setAllVideoList(state,data){
            let allVideonavListSSR=[]

            data.forEach(item => {
                if(item.tagCode && item.tagCode.search('video')!=-1){
                    allVideonavListSSR.push(item)
                }
            });
            state.allVideoNavList=allVideonavListSSR
        },
        setIsTopVideoList(state,data){
            let isTopVideonavListSSR=[]
            data.forEach(item => {
                if(item.tagCode && item.tagCode.search('isTop')!=-1){
                    console.log();
                    isTopVideonavListSSR.push(item)
                }
            });
            // console.log('置顶的视频导航',isTopVideonavListSSR);
            state.isTopVideoList=isTopVideonavListSSR
        },
    },
    actions: {
        //获取用户信息
        getUserInfo(context) {
            getOneMemberDetail({}, res => {
                if (res.data.header.code != 0) {
                    window.localStorage.clear()
                } else {
                    context.commit('userinfo', res.data.body)
                }
            })
        },
    }
}