<template>
  <div class="main custom-loading-svg" v-if="isShow"
  >
    <div class="small " :class="{'smallLeft':type2IsShow}" style="padding-right:2.5rem">
        <div class="smallBox">
            <div class="title"> 
                <span style="fontSize:1.25rem;fontWeight: 700;">{{obj0.featureName}}</span>
                <span>·</span>
                <span style="fontSize:1rem;text-align: end;">{{obj0.navName}}</span>
                <p style="color:#B33422;margin-left:1.25rem;text-align: end;"></p>
            </div>
            <div class="imgBox" @click="handleClickJumpArticle(zjObj)">
                <img :src="zjObj.listImage" alt="">
                <!-- <div class="objName">
                    {{zjObj.name}}
                </div> -->
            </div>
            <div class="list">
                <div class="item" v-for="(it,index) in dataList1" :key="index" @click="handleClickJumpArticle(it)">
                    <!-- <div class="imgBox2">
                        <img :src="it.listImage" alt="">
                    </div> -->
                    <div class="content">
                        <p class="fontSSR">{{it.articleName}}</p>
                        <p class="fontSSR" style="fontSize:1rem;color:#A7A7A7;">{{it.startDateStr}}</p>
                        <div class="link"></div>
                    </div>
                </div>
            </div>
            <div class="boxx"></div>
        </div>
        <div class="more">
            <div class="mix">
                <span>查看更多</span>
                <img :src="moreIMG" alt="" style="width: 3.5625rem;height: 1.1875rem;margin-left:.625rem">
            </div>
        </div>
    </div>
    <div class="big" :class="{'bigTop':type2IsShow}" @click="handleClickJumpArticle(hzObj)" v-on:mouseout="start()" v-on:mouseover="stop()">
        <div class="centerBox">
            <div class="topIMG">
                <img :src="hzObj?hzObj.listImage:loadingIMG" alt="">
            </div>
            <div class="NavName">
                <span style="margin-right:1.25rem">——</span>
                <span style="margin-right:.75rem">{{obj2?obj2.name:''}}</span>
                <span>{{obj2?obj2.shortName:''}}</span>
                <span style="margin-left:1.25rem">——</span>
            </div>
            <div class="centerContent">
                <div class="titleName">
                    {{hzObj?hzObj.name:''}}
                </div>
                <div class="contentTEXT">
                    {{hzObj?hzObj.description:''}}
                </div>
            </div>
            <div class="boxx"></div>
        </div>
        <div class="row">
            <div class="RowBox">
                <div class="leftRow">
                    <el-icon @click.stop="arrowLeft"><ArrowLeftBold /></el-icon>
                </div>
                <div class="dropList">
                    <ul>
                        <li v-for="(it,index) in dataList3" :key="index" :class="{'active':isActive==index}" @click.stop="stik(index)" class="drop"></li>
                    </ul>
                </div>
                <div class="rightRow">
                    <el-icon @click.stop="arrowRight"><ArrowRightBold /></el-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="small" :class="{'smallRight':type2IsShow}" style="padding-left:2.5rem">
        <div class="smallBox">
            <div class="title">
                <span style="fontSize:1.25rem;fontWeight: 700;">{{obj1.featureName}}</span>
                <span>·</span>
                <span style="fontSize:1rem">{{obj1.navName}}</span>
                <p style="color:#B33422;margin-left:1.25rem;text-align: end;"></p>
            </div>
            <div class="imgBox" @click="handleClickJumpArticle(zyObj)">
                <img :src="zyObj.listImage" alt="">
            </div>
            <div class="list">
                <div class="item" v-for="(it,index) in dataList2" :key="index" @click="handleClickJumpArticle(it)">
                    <!-- <div class="imgBox2">
                        <img :src="it.listImage" alt="">
                    </div> -->
                    <div class="content">
                        <p class="fontSSR">{{it.articleName}}</p>
                        <p class="fontSSR" style="color: #A7A7A7;">{{it.startDateStr}}</p>
                        <div class="link"></div>
                    </div>
                </div>
            </div>
            <div class="boxx"></div>
        </div>
        <div class="more2">
            <div class="mix">
                <span>查看更多</span>
                <img :src="moreIMG" alt="" style="width: 3.5625rem;height: 1.1875rem;margin-left:.625rem">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Common from "@/common/common";
import moreIMG from '@/assets/img/main/查看更多2.png'
import rowIMG from '@/assets/img/main/切换箭头.png'
import loadingIMG from '@/assets/img/main/loading.jpg'
import { ArrowLeftBold,ArrowRightBold} from "@element-plus/icons-vue";
import {
  getArticlePublishList,
  getNavigatorList,
} from "@/common/api.js";
import { mapState } from 'vuex';
export default {
    props: {
        id:{
            type:String,
            required:true,
        },
        isShow:{
            type:Boolean,
            default:true,
        }
    },
    components:{
        ArrowLeftBold,
        ArrowRightBold,
    },
    data(){
        return {
            loadingIMG,
            dataList1:[],//置顶
            dataList2:[],//置顶
            dataList3:[],//置顶
            obj0:{},
            obj1:{},
            obj2:{},
            zjObj:{},
            zyObj:{},
            hzObj:{},
            dataListIsGood:[],//精选
            navList:[],
            moreIMG,
            rowIMG,
            isActive:0,//很重要，标记轮播图的
            trim:null,
            loading:true,
            svg:`
            <path class="path" d="
                M 30 15
                L 28 17
                M 25.61 25.61
                A 15 15, 0, 0, 1, 15 30
                A 15 15, 0, 1, 1, 27.99 7.5
                L 15 15
            " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
            `,
        }
    },
    watch:{
        id:{
            handler(val){
                // this.getArticlePublishListSSR(val)
                // this.getArticlePublishListSSR2(val)
            }
        }
    },
    created(){
        // this.getArticlePublishListSSR(this.id)
        // this.getArticlePublishListSSR2(this.id)
        this.getNavigatorList()
        // let str='2023-09-14'
        // console.log(str.split('-'));
    },
    computed:{
        ...mapState({
            type2IsShow:state=>state.user.type2IsShow
        }),
    },
    mounted(){
        // this.trim=setInterval(()=>{
        //     this.arrowLeft()
        // },3000)
        this.start()
    },
    methods:{
        // 获取推荐文章列表--置顶
        getArticlePublishListSSR(navigatorID){
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:navigatorID,
                // contentType:99,
                pageNumber:4,
                sortTypeStartdate:1,
                isValid:1,
                isTop:1,
            },res=>{
                if(navigatorID=='811f72277cd64e54b04ce75daa075453'){    
                    this.dataList1=res.data.body.data.rows
                    getArticlePublishList({
                        companyID:Common.companyID,
                        navigatorID:navigatorID,
                        pageNumber:1,
                        sortTypeOrderSeq:2,
                        isValid:1,
                        isTop:1,
                    },response=>{
                        this.zjObj=response.data.body.data.rows[0]
                    })
                }else if(navigatorID=='ab3dac5e0b4f416182dfaf42fc21689e'){   
                    this.dataList2=res.data.body.data.rows
                    getArticlePublishList({
                        companyID:Common.companyID,
                        navigatorID:navigatorID,
                        pageNumber:1,
                        sortTypeOrderSeq:2,
                        isValid:1,
                        isTop:1,
                    },response=>{
                        this.zyObj=response.data.body.data.rows[0]
                    })
                }else{
                    this.dataList3=res.data.body.data.rows
                    this.total=res.data.body.data.total
                    this.hzObj=res.data.body.data.rows[0]
                    this.isActive=0
                }
                this.loading=false
            })
        },
        // 获取推荐文章列表--精选
        getArticlePublishListSSR2(val){
            getArticlePublishList({
                companyID:Common.companyID,
                navigatorID:val,
                // contentType:99,
                pageNumber:99,
                sortTypeStartdate:1,
                isValid:1,
                isGood:1,
            },res=>{
                this.dataListIsGood=res.data.body.data.rows
                
            })
        },
        // 获取导航
        getNavigatorList(){
            this.loading=true
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: this.id,
                sortTypeOrder: 1,
            }, res => {
                this.navList=res.data.body.data.rows
                res.data.body.data.rows.forEach((it)=>{
                    if(it.navigatorID=='811f72277cd64e54b04ce75daa075453'){
                        this.obj0=it
                        this.obj0.featureName=it.description.split('·')[0]
                        this.obj0.navName=it.description.split('·')[1]
                    }
                    if(it.navigatorID=='ab3dac5e0b4f416182dfaf42fc21689e'){
                        this.obj1=it
                        this.obj1.featureName=it.description.split('·')[0]
                        this.obj1.navName=it.description.split('·')[1]
                    }
                    if(it.navigatorID=='adfc80e65c4842039cd8a1e48fa32554'){
                        this.obj2=it
                        // this.obj2.featureName=it.description.split('·')[0]
                        // this.obj2.navName=it.description.split('·')[1]
                    }
                    this.getArticlePublishListSSR(it.navigatorID)
                })
            })
        },
        arrowLeft(){
            // console.log('arrowLeftNEW',this.isActive);
            if(this.isActive==0){
                this.isActive=this.total-1
            }else{
                this.isActive=this.isActive-1
            }
            // console.log('arrowLeft',this.isActive);
            this.hzObj=this.dataList3[this.isActive]
        },
        arrowRight(){
            // console.log('arrowRightNEW',this.isActive);
            if(this.isActive==this.total-1){
                this.isActive=0
            }else{
                this.isActive=this.isActive+1
            }
            // console.log('arrowRight',this.isActive);
            this.hzObj=this.dataList3[this.isActive]
        },
        stik(index){
            this.isActive=index
            this.hzObj=this.dataList3[this.isActive]
        },
        // 定时器
        // 文章跳转
        handleClickJumpArticle(row){
            console.log(row);
            if(row.functionURL && row.functionURL!="undefined"){
                window.open(row.functionURL, '_blank');
            }else{
                this.$router.push(
                    {
                    path:'/articleDetailsNews',
                    query:{
                        articleID:row.articleID,
                        navigatorID: this.id,
                    }
                    }
                )
            }
            // this.$router.push(
            //     {
            //         path:'/articleDetailsNews',
            //         query:{
            //             articleID:row.articleID,
            //             navigatorID: this.id,
            //         }
            //     }
            // )
        },
        // 跳转到新闻列表页
        handleClickJump(row){
            this.$router.push(
                {
                path:'/navListNews',
                query:{
                    navigatorID:row.navigatorID,
                }
                }
            )
        },
        // 鼠标悬停，轮播图停止切换
        stop(){//鼠标悬停到图片上停止
            // console.log('鼠标悬停到图片上停止');
            clearInterval(this.trim);
        },
        // 鼠标离开，轮播图开始轮播
        start(){//鼠标离开图片继续
            // console.log('鼠标离开图片继续');
            this.trim=setInterval(()=>{
                this.arrowLeft()
            },3000)
        },
    }
}
</script>

<style scoped lang='scss'>
.main{
    margin-top: 1.875rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.5rem;
    .small{
        width: 25%;
        padding-bottom: 3.75rem;
        position: relative;
        transform:translateY(20%);
        .smallBox{
            width: 100%;
            .title{
                cursor: pointer;
                display: flex;
                align-items: end;
                p{
                    width: 1.875rem;
                    height: 1.5625rem;
                    border-bottom: .0625rem solid #B33422;
                    // background: #B33422;
                }
            }
            .title:hover{
                color:#2878ff;
            }
            .imgBox{
                width: 100%;
                height: 11.4375rem;
                overflow: hidden;
                cursor: pointer;
                margin-top: 1.25rem;
                position: relative;
                img{
                    width: 100%;
                    height: 11.4375rem;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                img:hover{
                    transform: scale(1.2);
                }
                .objName{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2.1875rem;
                    padding: 0 .625rem;
                    color: #fff;
                    font-size: 1rem;
                    line-height: 2.1875rem;
                    background-color: rgba($color: #000000, $alpha: 0.8);
                    -webkit-line-clamp: 1;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                    display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                    -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                    overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                    text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                }
            }
            .list{
                width: 100%;
                .item{
                    width: 100%;
                    display: flex;
                    height: 4.5625rem;
                    margin-top: 1.3rem;
                    justify-content: space-between;
                    cursor: pointer;
                    .imgBox2{
                        width: 33%;
                        height: 4.5625rem;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 4.5625rem;
                            object-fit: cover;
                            transition: transform 0.3s ease-in-out;
                        }
                        
                    }
                    .content{
                        width: 100%;
                        height: 4.5625rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .fontSSR{
                            width: 100%;
                            font-size: 1.005rem;
                            font-weight: 500;
                            color: #555;
                            -webkit-line-clamp: 1;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                            display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                            -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                            overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                            text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                        }
                        .link{
                            width: 100%;
                            height: .0625rem;
                            background: #D2D2D2;
                        }
                    }
                }
                .item:hover{
                    img{
                        transform: scale(1.2);
                    }
                    .fontSSR{
                        color:#2878ff;
                    }
                    .link{
                        animation: linkWidth 500ms linear;
                        background-color: #1d5d93;
                    }
                    @keyframes linkWidth {
                        0% {width: 0%;}
                        100% {width: 100%;}
                    }
                }
            }
            .boxx{
                width: 100%;
                height: 3.75rem;
            }
        }
        .more{
            width: 100%;
            cursor: pointer;
            position: absolute;
            bottom: 2.5rem;
            left: -2.5rem;
            .mix{
                float:right;
                display: flex;
                align-items: center;
                span{
                    font-size: 1rem;
                }
                // padding:20px 0;
            }
        }
        .more:hover{
            color: #2878ff;
        }
        .more2{
            width: 100%;
            cursor: pointer;
            position: absolute;
            bottom: 2.5rem;
            left: 0;
            .mix{
                float:right;
                display: flex;
                align-items: center;
                span{
                    font-size: 1rem;
                }
                // padding:20px 0;
            }
        }
        .more2:hover{
            color: #2878ff;
        }
    }
    .big{
        width: 50%;
        padding-bottom: 3.75rem;
        border-left: .0625rem solid #D2D2D2;
        border-right: .0625rem solid #D2D2D2;
        position: relative;
        transform:translateY(10%);
        cursor: pointer;
        .centerBox{
            width: 100%;
            .topIMG{
                width: 100%;
                height: 23.75rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .NavName{
                font-size: 1rem;
                color: #B33422;
                text-align: center;
                margin-top: 1.5625rem;
            }
            .centerContent{
                width: 100%;
                height: 12.25rem;
                margin-top: 1.25rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                .titleName{
                    width: 70%;
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #333333;
                    line-height: 2.0625rem;
                }
                .contentTEXT{
                    margin-top: 1.75rem;
                    width: 80%;
                    font-size: 1rem;
                    font-weight: 400;
                    color: #666666;
                    line-height: 2.125rem;
                    text-align: center;
                    -webkit-line-clamp: 4;//（用来限制在一个块元素显示的文本的行数，2 表示最多显示 2 行。为了实现该效果，它需要组合其他的 WebKit 属性）
                    display: -webkit-box;//（和 1 结合使用，将对象作为弹性伸缩盒子模型显示 ）
                    -webkit-box-orient: vertical;//（和 1 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）
                    overflow: hidden;//（文本溢出限定的宽度就隐藏内容）
                    text-overflow: ellipsis;//（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)
                }
            }
            .boxx{
                width: 100%;
                height: 0.75rem;
            }
        }
        .row{
            position: absolute;
            bottom: 2.5rem;
            left: 0;
            width: 100%;
            height: 1rem;
            display: flex;
            justify-content: center;
            .RowBox{
                width: 88%;
                height: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .leftRow{
                    cursor: pointer;
                }
                .leftRow:hover{
                    color: #2878ff;
                }
                .dropList{
                    flex: 1;
                    ul{
                        display: flex;
                        justify-content: center;
                        .drop{
                            margin: 0 .3125rem;
                            width: .625rem;
                            height: .625rem;
                            background: #A0A0A0;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                        .active{
                            background: #1f296a;
                        }
                    }
                    
                }
                .rightRow{
                    cursor: pointer;
                }
                .rightRow:hover{
                    color: #2878ff;
                }
            }
        }
    }
    .smallLeft{
        transform:translateY(0%);
        transition: all 400ms linear;
        animation: floatShow 400ms linear;
        // transition: all 1000ms;
        // -moz-animation: myfirst 5s;	/* Firefox */
        // -webkit-animation: myfirst 5s;	/* Safari 和 Chrome */
        // -o-animation: myfirst 5s;	/* Opera */
    }
    @keyframes floatShow
    {
        0%   {opacity: 0;}
        25%  {opacity: 0.25;}
        50%  {opacity: 0.5;}
        75%  {opacity: 0.6;}
        90%  {opacity: 0.8;}
        100% {opacity: 1;}
    }
    /*定义动画*/
    @keyframes myfirst
    {
        0%   {transform:translateX(-100%);opacity: 0;}
        25%  {transform:translateX(-75%);opacity: 0.25;}
        50%  {transform:translateX(-50%);opacity: 0.5;}
        75%  {transform:translateX(-25%);opacity: 0.6;}
        90%  {transform:translateX(-10%);opacity: 0.8;}
        100% {transform:translateX(0%);opacity: 1;}
    }
    .smallRight{
        transform:translateY(0%);
        transition: all 400ms linear;
        animation: floatShow 400ms linear;
        // animation: myfirst3 200ms linear;
        // transition: all 1000ms;
        // -moz-animation: myfirst 5s;	/* Firefox */
        // -webkit-animation: myfirst 5s;	/* Safari 和 Chrome */
        // -o-animation: myfirst 5s;	/* Opera */
    }
    /*定义动画*/
    @keyframes myfirst2
    {
        0%   {transform:translateX(100%);}
        25%  {transform:translateX(75%);}
        50%  {transform:translateX(50%);}
        75%  {transform:translateX(25%);}
        100% {transform:translateX(0%);}
    }
    .bigTop{
        // animation: myfirst3 500ms linear;
        transform:translateY(0%);
        transition: all 400ms linear;
        // animation: floatShow 400ms linear;
    }
    /*定义动画*/
    @keyframes myfirst3
    {
        0%   {transform:translateY(20%);}
        25%  {transform:translateY(7%);}
        50%  {transform:translateY(5%);}
        75%  {transform:translateY(3%);}
        100% {transform:translateY(0%);}
    }
}
.example-showcase .el-loading-mask {
  z-index: 9;
}

</style>