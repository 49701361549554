<template>
  <header :class="{'active':store.state.user.changeHeaderColor,'headerMain':!store.state.user.changeHeaderColor}">
    <div class="header" >
      <div class="changeHeaderBox">
        <div class="header_logo" @click.stop="handleClickNavJumpHome()">
          <img :src="!store.state.user.changeHeaderColor?logoImg2:logoImg" alt="" style="width:3.75rem;height:3.75rem;">
          <img :src="!store.state.user.changeHeaderColor?logoText2:logoText" alt="" style="width:7.75rem;height:3.125rem;margin-left:1.25rem;">
        </div>
        <div class="shuLink" :style="{'background':!store.state.user.changeHeaderColor?'#fff':'#1f296a'}" v-if="store.state.nav.navCode==1"></div>
        <div class="libraryText" v-if="store.state.nav.navCode==1">
          <div class="libraryTextFont" :style="{'color':!store.state.user.changeHeaderColor?'#fff':'#1f296a'}">图书馆</div>
          <div class="libraryTextEnglish" :style="{'color':!store.state.user.changeHeaderColor?'#fff':'#1f296a'}">LIBRARY</div>
        </div>
      </div>
      <ul>
        <li
          v-for="(item, index) in store?.state?.nav?.headerList"
          @click="handleClickNavJump($event,item)"
          :key="index"
          @mouseenter="switchActive"
          @mouseleave="noSwitchActive"
        >
          <img v-if="item.shortName=='Search'" :src="!store.state.user.changeHeaderColor?item.smallIcon:item.largeIcon" alt="" style="width: 1.5rem;">
          <span style="font-size: 1rem;color:#fff;letter-spacing: .0625rem;" :style="{'color':store.state.publicSSR.spanColor==item.name?'#58b6e5':store.state.user.changeHeaderColor?'#1f296a':'#fff'}" v-else>{{ store.state.language ? item.name : item.shortName }}</span>
        </li>
        <div class="activeLi" :style="{'width':`${liActiveWidth}px`,'backgroundColor':store.state.user.changeHeaderColor?'#58b6e5':'#fff',}" ref="activeLi"></div>
      </ul>

    </div>
  </header>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { inject, reactive,ref } from "vue";
import Common from "@/common/common.js";
import Jump from '@/components/js/index.js'
import {
  getCartGoodsTotal,
} from "@/common/api.js";
import logoImg2 from '@/assets/img/main/logo白.png'
import logoImg from '@/assets/img/main/logoSkyimg.png'
import logoText from '@/assets/img/main/logoSkyfont.png'
import logoText2 from '@/assets/img/main/logoTEXT白.png'
import searchIMG from '@/assets/img/main/搜索.png'
import searchIMG2 from '@/assets/img/main/搜索白.png'
let login = inject("login");
const store = useStore();
const router = useRouter();
let clickArr = ["我的账户", "购物车"];
const data = reactive({
  language: Common.language,
});

let props = defineProps(['isActiveSSE','linkIsShow'])
// const isActiveSSE=ref(false)
const bthClick = (item) => {
  //   console.log(Common.language)
  // console.log(item);
  if (item.navigatorID == "0b505ea95b104c59830bc70ea64cc11f") {
    Common.language = !Common.language;
    data.language = !data.language;
    store.commit('languageState', data.language)
  } else {
    router.push(item.functionURL);
  }
};
const offsetLeft=ref(14)
// 鼠标移动获取坐标
const activeLi=ref(activeLi)
const activeLi2=ref(activeLi2)
const liActiveWidth=ref(80)
const activeLiIsShow=ref(false)
// const spanColor=ref()
const handleClickNavJump=(e,row)=>{
  store.commit('publicSSR/setSpanColor',e.target.innerText);
  // 注：有一些没开发的跳转到loading页面
  // || row.navigatorID=='631e08c1db2b483a918691c5666d20a0' 现在校园服务可以正常访问
  // || row.navigatorID=='a97832c85f2944a285037eaf47934cfd' 现在图书馆可以正常访问
  if(row.navigatorID=='fb91e9a93e5b4a40a8bbb2b1f2387622' || row.navigatorID=='ae8a8aec570548b3baec18c4a3142762'){
    store.commit('user/setChangeHeaderColor',true);
    Jump.handleClickNavJump({
      path:'/loading',
    })
  }else if(row.navigatorID=='f0d556e8fb2d4e3b8acd3cd8f0db6fb9'){
    // 特殊处理，当点击教育教学-（现在更名成了新闻资讯），就跳转到新闻页面--以后会改回来，因为现在的教育教学没写
    store.commit('user/setChangeHeaderColor',false);
    Jump.handleClickNavJump({
      path:'/news',
      navigatorID:'190e6cbb80f245e79832cf8b6750c634'
    })
  }else if(row.navigatorID=='02ad7adaa33949ddb0becd5329ef90c6'){//搜索
    store.commit('user/setChangeHeaderColor',false);
    Jump.handleClickNavJump({
      path:row.functionURL,
    })
  }else if(row.navigatorID=='a97832c85f2944a285037eaf47934cfd'){//图书馆
    store.commit('user/setChangeHeaderColor',false);
    router.push({
        path:row.functionURL,
    })
  }else{
    store.commit('user/setChangeHeaderColor',false);
    Jump.handleClickNavJump({
      path:row.functionURL,
      navigatorID:row.navigatorID
    })
  }
}
const handleClickNavJumpHome=()=>{
  store.commit('user/setChangeHeaderColor',false);
  // spanColor.value=''
  store.commit('publicSSR/setSpanColor','');
  Jump.handleClickNavJumpHome({
    path:'/',
  })
}

const switchActive=(e)=>{
  // console.log(e);
  activeLiIsShow.value=true
  activeLi.value.style.transform=`translateX(${e.target.offsetLeft}px)`
  activeLi.value.style.left='.625rem'
  // activeLi2.value.style.display='none'
  liActiveWidth.value=(e.target.offsetWidth-20)
  // if(e.target.innerText.length<4){
  //   liActiveWidth.value=60
  // }else{
  //   liActiveWidth.value=80
  // }
  // if(e.target.innerText.length==0){
  //   liActiveWidth.value=40
  // }
}
const noSwitchActive=(e)=>{
  // activeLiIsShow.value=false
  activeLi.value.style.transform=`translateX(${offsetLeft.value}px)`
  // activeLi2.value.style.display='block'
}

</script>
<style lang="scss" scoped>
header {
  width: 100%;
  .link{
    width: 821px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 17px;
    right: 270px;
  }
  .linkSSR{
    width: 821px;
    height: 2px;
    background: #58B6E5;
    position: absolute;
    bottom: 17px;
    right: 270px;
  }
}

.header {
  width: 100%;
  height: 100%;
  min-width: 66.25rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    height: 1.75rem;
    font-size: 2.25rem;
    font-weight: bold;
    color: #000000;
    line-height: 1.4375rem;
    padding-left: 3.75rem;
  }

  > ul {
    display: flex;
    position: relative;
    // margin-right: 67px;

    li {
      // margin: 0 .9375rem;
      font-size: 1rem;
      font-weight: bold;
      color: #545151;
      line-height: 1.4375rem;
      cursor: pointer;
      position: relative;
      padding: .625rem .9375rem;
      
      .cartNumber{
        width: 1.875rem;
        height: 1.875rem;
        text-align: center;
        line-height: 1.875rem;
        border-radius: 100%;
        background: red;
        color: #ffffff;
        position: absolute;
        right: -0.3125rem;
        top: -0.625rem;
      }
      .item {
        margin-top: -0.625rem;
      }
    }
    .activeLi{
      position: absolute;
      bottom: 0;
      left: .625rem;
      width: 5rem;
      height: .125rem;
      background-color: #fff;
      transition: all 500ms;
      display: none;
    }
    .activeLi2{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 5rem;
      height: .125rem;
      background-color: #fff;
      transition: all 500ms;
      display: none;
    }
    // 58b6e5
  }
  ul:hover{
    .activeLi
    {
      display: block;
    }
  }
  .changeHeaderBox{
    display: flex;
    align-items: center;
    .header_logo{
      width: 13.125rem;
      height: 3.75rem;
      cursor: pointer;
    }
    .shuLink{
      width: 1px;
      margin: 0 .75rem;
      height: 2.5625rem;
    }
    .libraryText{
      width: 4.625rem;
      height: 3.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .libraryTextFont{
        font-weight: bold;
        font-size: 1.4375rem;
      }
      .libraryTextEnglish{
        font-weight: 400;
        font-size: .75rem;
        letter-spacing: 2px;
      }
    }
  }
}
.headerMain{
  // background:linear-gradient(to bottom,rgba(0,0,0,0.5),transparent);
}
.active{
  // background: linear-gradient(to bottom,rgba(0,0,0,0.5),transparent);
  // background-color: #fff !important;
  // height: 100px;
  padding-top: .625rem;
  .header{
    ul{
      li{
        span{
          // color: #1f296a !important;
          letter-spacing: .0625rem;
        }
      }
    }
  }
}

</style>