<template>
    <footer v-if="otherShow">
        <div class="footer">
            <div class="topBox">
                <div class="linkSSE" v-if="linkSSEShow"></div>
                <div class="linkBox_Title">
                    <div class="titleImg">
                        <!-- <img src="http://file.sdlb.cc/banner/01.png" alt=""> -->
                        <div class="Aname">
                            常用·链接
                        </div>
                        <div class="engIMG">
                            <img src="http://file.sdlb.cc/banner/Common%20Links.png" alt="">
                        </div>
                        <div class="link"></div>
                    </div>
                    <div class="title">
                        <!-- 这里放标题 -->
                    </div>
                    <div class="titleLin">
                        <!-- 这是标题下面的短线 -->
                    </div>
                </div>
                <div class="linkBox_itemBox">
                    <div
                    class="item"
                    v-for="(item,index) in state?.nav?.commonLinkList"
                    :key="index"
                    @click.stop="handleClickNavJump(item)"
                    >
                    <span>{{item.name}}</span>
                    <div class="Itemlink">
                    </div>
                    </div>
                    
                </div>
            </div>
            <div class="botBox">
                <div class="kkk" style="width: 100%;height:.9375rem;"></div>
                <div class="botLogo">
                    <div class="logo">
                        <img :src="logoIMG2" alt="">
                    </div>
                    <div class="other">
                        <div class="item" v-for="(it,index) in data.list" :key="index">
                            <img :src="it.img" alt="">
                        </div>
                    </div>
                </div>
                <div class="friend">
                    {{state?.nav?.friendshipObj.name?state?.nav?.friendshipObj.name:'友情连接'}}:
                    <div class="item" v-for="(item,index) in state?.nav?.friendshipLinkList" :key="index" @click="jumpOutWeb(item)">
                        {{item.name}}
                    </div>
                </div>
                <div class="text">
                    <div class="item" v-for="(it,index) in data.array" :key='index'>
                        {{it.text}}
                    </div>
                </div>
            </div>
            <div class="qrCode">
            <div class="box">
                <img :src="data.qrUrl" alt="">
            </div>
            <p>欢迎关注鲁班学院公众号</p>
        </div>
        </div>
        
    </footer>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from "vue-router"
import { ElMessage } from "element-plus";
// import linkIMG from '@/assets/img/main/link.png'
import linkIMG from '@/assets/img/main/link2.png'
import logoText from '@/assets/img/main/logoTEXT.png'
// import logoIMG2 from '@/assets/img/main/logo2.png'
import logoIMG2 from '@/assets/img/main/log3.png'
import weibo from '@/assets/img/main/微博.png'
import weixin from '@/assets/img/main/微信.png'
import tel from '@/assets/img/main/电话.png'
import email from '@/assets/img/main/邮箱.png'
// import qrcode from '@/assets/img/main/二维码.png'
import qrcode from '@/assets/img/main/weixinQR.png'
import { inject, reactive,ref } from "vue";
import {
  getOneWeixinDetail,
} from "@/common/api.js";
const {state} = useStore();
const router = useRouter();
let props = defineProps(['linkSSEShow','otherShow'])
const toPath = (ite) => {
    if(!ite.articleID)return
    if(ite.title.length>20) {
        ElMessage({
            message: "无内容",
            type: "warning",
        });
        return
    }
    router.push(`/articleDetails?articleID=${ite.articleID}`)
}
const data=reactive({
    list:[
        {
            name:'微博',
            url:'',
            img:weibo,
        },
        {
            name:'微信',
            url:'',
            img:weixin,
        },
        {
            name:'电话',
            url:'',
            img:tel,
        },
        {
            name:'邮箱',
            url:'',
            img:email,
        },
    ],
    array:[
        {
            text:'版权所有©鲁班学院'
        },
        {
            text:'地址：山东省枣庄市薛城区大学城'
        },
        {
            text:'邮编：277000 '
        },
        {
            text:'反馈意见：lbxy@sdlb.cc '
        },
        {
            text:'鲁ICP备2023030516号'
        },
    ],
    qrUrl:qrcode,
})
// 获取微信公众号详情
const getOneWeixinDetailSSR=()=>{
  getOneWeixinDetail({
    weixinID:'8a2f462a86c57c080186d90ec1ad17f9'
  },res=>{
    data.qrUrl=res.data.body.data.qrcodepath?res.data.body.data.qrcodepath:qrcode
  })
}
getOneWeixinDetailSSR()
// console.log('常用链接',state?.nav?.commonLinkList);
import Jump from '@/components/js/index.js'
const handleClickNavJump=(row)=>{
    console.log('点击常用链接',row);
    let navigatorIDSSR=''
    if(row.navigatorID=='22e0e045346c4d319f2272120bcdbda3'){
        Jump.handleClickNavJumpHome({
            path:'/',
        })
        return 
    }else if(row.navigatorID=='d9e65a06f61c44e6ade7b6dce86b171a'){
        navigatorIDSSR='d1a374b7821c4669b39fd5ec7862f22c'
    }else if(row.navigatorID=='5df84466919a47f2a98697504a406450'){
        navigatorIDSSR='190e6cbb80f245e79832cf8b6750c634'
    }else if(row.navigatorID=='62d172b0bd7b4f39995a365adc4a4958'){
        navigatorIDSSR='a79515c42e25431a970051b874578904'
    }else{

    }
  Jump.handleClickNavJump({
    path:row.functionURL,
    navigatorID:navigatorIDSSR,
  })
}
const jumpOutWeb=(row)=>{
    // console.log(row.functionURL);
    window.open(row.functionURL, '_blank');
}
</script>
<style lang="scss" scoped>
footer {
    width: 100%;
    height: 37.625rem;
    // background: #1F2A2C;
}

.footer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: aliceblue;
    position: relative;
    z-index: 2;
    .topBox{
        width: 100%;
        height: 21.875rem;
        background-color: #f2f2f2;
        padding: 0 13.5%;
        .linkSSE{
            width: 100%;
            height: .0625rem;
            background-color: #f2f2f2;
        }
        .linkBox_Title{
            width: 24.375rem;
            height: 5.125rem;
            margin-top: 2.3125rem;
            cursor: pointer;
            .titleImg{
                width: 22.75rem;
                height: 5.0625rem;
                position: relative;
                // img{
                //     width: 22.75rem;
                //     height: 5.0625rem;
                // }
                .engIMG{
                width: 21.25rem;
                position: absolute;
                top: -0.9375rem;
                left: 0;
                // transform: translateX(-50%);
                // background-color: red;
                img{
                    width: 100%;
                    height: 100%;
                }
                }
                .Aname{
                font-size: 2.25rem;
                font-weight: 500;
                color: #1f296a;
                letter-spacing: .1875rem;
                }
                .link{
                width: 8.375rem;
                height: .25rem;
                margin-top: .625rem;
                background: #1f296a;
                }
            }
        }
        .linkBox_itemBox{
            width: 70%;
            min-height: 13.125rem;
            margin-left: 2.5rem;
            margin-bottom: 2.5rem;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            // align-items: ;
            .item{
                width: 33%;
                // margin-right:75px;
                margin-top: 1.875rem;
                height: 2.5rem;
                // border-bottom: .0625rem solid #D2D2D2;
                color: #666;
                font-size: 1rem;
                cursor: pointer;
                position: relative;
                span{
                    letter-spacing: 0.15rem;
                }
                .Itemlink{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 2;
                    width: 70%;
                    height: .0625rem;
                    background-color: #D2D2D2;
                }
            }
            .item::before{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 3;
                width: 0%;
                height: .0625rem;
                background-color: #1f296a;
                transition: all 500ms linear;
            }
            .item:hover::before{
                    width: 70%;
            }
            .item:hover{
                color: #1f296a;
                // border-bottom:none;
            }
        }

    }
    .botBox{
        width: 100%;
        flex: 1;
        // background-color: #1f296a;
        background-image: url('@/img/footBGimg.png');
        padding: 0 13.5%;
        .botLogo{
            width: 100%;
            // height: 70px;
            display: flex;
            align-items: center;
            .logo{
                width: 20.625rem;
                // height: 70px;
                // margin-top: 20px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .other{
                flex: 1;
                display: flex;
                align-items: center;
                margin-left: 3.75rem;
                .item{
                    margin-right: 2.5rem;
                    width: 2.75rem;
                    height: 2.75rem;
                    border-radius: 50%;
                    // background-color: #fff;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .friend{
            margin-top: 1.25rem;
            display: flex;
            color: #D2D2D2;
            font-size: .875rem;
            border-bottom: .0625rem solid #E8E8F1;
            padding-bottom: 1.25rem;
            .item{
                cursor: pointer;
                padding: 0 1.25rem;
                border-right: .0625rem solid #D2D2D2;
                
            }
        }
        .friend :last-child{
            border-right: none;
        }
        .text{
            font-size: .875rem;
            color: #D2D2D2;
            display: flex;
            margin-top: 1.25rem;
            padding-bottom: 1.25rem;
            .item{
                padding: 0 1.25rem;
                border-right: .0625rem solid #D2D2D2;
            }
        }
        .text :first-child{
            padding-left: 0;
        }
        .text :last-child{
            border-right: none;
        }
    }
    .qrCode{
        width: 15rem;
        position: absolute;
        right: 13.5%;
        top: 50%;
        transform: translateY(-50%);
        .box{
            width: 15rem;
            height: 15rem;
            padding: 1.25rem;
            background-color: #fff;
            img{
                width: 12.5rem;
                height: 12.5rem;
                text-align: center;
            }
        }
        p{
            width: 100%;
            margin-top: .875rem;
            color: #fff;
            font-size: .875rem;
            text-align: center;
        }
}
    
}

</style>