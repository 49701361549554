<template>
  <header>
    <div class="main"> 
        <div class="topHeader">
            <div class="boxLeft">
                <!-- <div 
                class="item"
                >
                English
                </div> -->
                <div 
                class="item"
                v-for="(item, index) in topHeaderListLeft"
                :key="index"
                >
                {{item.name}}
                </div>
                <!-- <div 
                class="item"
                >
                手机版
                </div> -->
            </div>
            <div class="boxLeft">
                <!-- <div 
                class="item"
                >
                登录/注册
                </div> -->
                <div 
                class="item"
                v-for="(item, index) in topHeaderListRight"
                :key="index"
                @click="handlerClick(item)"
                >
                <img v-if="item.shortName=='Map'" :src="item.largeIcon" alt="">
                <span v-else>{{item.name}}</span>

                </div>
                <!-- <div 
                class="item"
                >
                <img :src="mapIMG" alt="">
                </div> -->
            </div>
        </div>
    </div>
  </header>
</template>

<script>
import api from "@/common/api.js";
import {mapState,mapActions} from 'vuex'
import mapIMG from '@/assets/img/main/地图.png'
export default {
    props:{
        isShow:{
            type:Boolean,
            default:false,
        }
    },
    data () {
        return {
            mapIMG,
        }
    },
    computed:{
        ...mapState({
            topHeaderListLeft:state =>state.nav.topHeaderListLeft,
            topHeaderListRight:state =>state.nav.topHeaderListRight
        })
    },
    created(){
    },
    methods:{
        // size每组数组多少个，如：8
        // array需要拆分的数组
        arrayChunk(array, size){
            let data = []
            for (let i = 0; i < array.length; i += size) {
                data.push(array.slice(i, i + size))
            }
            return data
        },
        handlerClick(row){
            let flag=!this.isShow
            this.$emit('openNavPage',flag)
        }

    }
}
</script>

<style scoped lang='scss'>
.main{
    width: 100%;
    min-width: 1060px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 666;
    .topHeader{
        width: 100%;
        min-width: 1060px;
        height: 52px;
        padding: 0 13.5%;
        background-color: #1f296a;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .boxLeft{
            display: flex;
            align-items: center;
            .item{
            color:#fff;
            padding: 0 20px;
            font-size: 12px;
            border-right: 1px solid #fff;
            font-size: 12px;
            cursor: pointer;
            }
        }
        .boxLeft :last-child{
            border-right: none;
        }
        
    }
}
</style>