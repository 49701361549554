import { getOneMemberDetail } from "../../common/api"
export default {
    namespaced: true,
    state() {
        return {
            spanColor:'',//控制导航选中高亮
        }
    },
    mutations: {
        setSpanColor(state, data) {
            state.spanColor = data
        },
    },
    actions: {
        //获取用户信息
        getUserInfo(context) {
            getOneMemberDetail({}, res => {
                if (res.data.header.code != 0) {
                    window.localStorage.clear()
                } else {
                    context.commit('userinfo', res.data.body)
                }
            })
        },
    }
}